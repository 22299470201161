import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { ReactNode } from "react";
import { classNames } from "core";
import { useComponentId } from "../../hooks/useComponentId";

export interface TextFieldProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    "prefix"
  > {
  label?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props: TextFieldProps, ref) => {
    const {
      label,
      className,
      value,
      defaultValue,
      id,
      prefix,
      suffix,
      autoFocus,
      ...rest
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    // Forward the ref to parent
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    // For some reason React's native autoFocus functionality doesn't work, so we manually handle it here.
    useEffect(() => {
      if (autoFocus) {
        inputRef?.current?.focus();
      }
    }, []);

    const textFieldId = useComponentId(id, "TextField");

    return (
      <div className={classNames("flex flex-col gap-1", className)}>
        {label && (
          <label htmlFor={textFieldId} className={"text-sm font-medium"}>
            {label}
          </label>
        )}
        <div
          className={
            "flex items-center overflow-hidden rounded border border-gray-200 bg-white"
          }
        >
          {prefix && (
            <div className={"h-fit w-fit pl-3 [&>span]:block"}>{prefix}</div>
          )}
          <input
            {...rest}
            id={textFieldId}
            ref={inputRef}
            value={value}
            defaultValue={defaultValue}
            className={
              "w-full px-3 py-2 text-sm font-medium placeholder-gray-400 focus:outline-none"
            }
            autoFocus={autoFocus}
          />
          {suffix && (
            <div className={"h-fit w-fit pl-3 [&>span]:block"}>{suffix}</div>
          )}
        </div>
      </div>
    );
  }
);

export default TextField;
