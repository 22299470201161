import React from "react";
import ActivityIndicator from "react-loader-spinner";
import { classNames } from "core";
import { ButtonVariant, Size } from "../types";
import { Tooltip } from "source/components/shared/Tooltip";
import { ConditionalWrapper } from "../shared";

export interface ButtonProps {
  label: string | React.ReactNode;
  onClick?: ((e: React.MouseEvent) => void) | (() => void);
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: ButtonVariant;
  size?: Size;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  styles?: string;
  labelStyles?: string;
  tooltip?: React.ReactElement | string;
  type?: string;
}

export const Button = ({
  label,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isDisabled = false,
  isLoading = false,
  variant = "primary",
  size = "sm",
  endIcon,
  startIcon,
  styles,
  labelStyles,
  tooltip,
}: ButtonProps) => (
  <ConditionalWrapper
    condition={!!tooltip}
    wrapper={(children) => (
      <Tooltip title={tooltip} enterDelay={400} enterNextDelay={400}>
        {children}
      </Tooltip>
    )}
  >
    <button
      className={classNames(
        variant === "primary" &&
          "border border-transparent bg-hebbiaBlue text-white hover:enabled:bg-darkBlue active:enabled:bg-hebbiaBlue disabled:bg-gray1 disabled:text-[#98A2B3]",
        variant === "secondary" &&
          "border bg-white text-darkGray hover:enabled:bg-gray05 active:enabled:bg-white disabled:text-opacity-60",
        variant === "text" &&
          "bg-white text-darkGray hover:enabled:text-mediumGray",
        variant === "text-secondary" &&
          "select-none text-darkGray hover:enabled:text-hebbiaBlueDark disabled:text-opacity-60",
        `flex items-center gap-1.5 duration-300 text-${size} cursor-pointer px-3 py-2 font-medium outline-none disabled:cursor-default`,
        ["primary", "secondary"].includes(variant) ? "shadow-sm" : "",
        styles
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={isDisabled}
    >
      {isLoading ? (
        <>
          <ActivityIndicator
            type="TailSpin"
            color="var(--text-light)"
            height={14}
            width={14}
          />
          <>{label}</>
        </>
      ) : typeof label === "string" ? (
        <>
          {startIcon}
          <span className={labelStyles}>{label}</span>
          {endIcon}
        </>
      ) : (
        label
      )}
    </button>
  </ConditionalWrapper>
);
