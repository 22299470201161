export type ModelType =
  | "gpt-3.5-turbo-16k-0613"
  | "gpt-3.5-turbo-0613"
  | "gpt-3.5-turbo-instruct"
  | "gpt-4-0613"
  | "gpt-4-32k-0314"
  | "gpt-4t-hebbia"
  | "gpt-4o"
  | "gpt-4o-mini"
  | "claude-instant-1.1"
  | "claude-2.0"
  | "claude-2.1"
  | "claude-3-haiku-20240307"
  | "claude-3-opus-20240229"
  | "claude-3-sonnet-20240229"
  | "claude-3-5-sonnet-20240620"
  | "chat-bison@001"
  | "command"
  | "command-light"
  | "text-bison-32k"
  | "gemini-pro"
  | "gemini-ultra"
  | "gemini-15-pro"
  | "gpt-4-1106-preview"
  | "meta.llama3-1-405b-instruct-v1:0"
  | "o1-preview"
  | "o1-mini";

export type LLM_CONFIG = {
  model: ModelType;
  label: string;
  tokenLimit: number;
  provider: LLMProvider;
  isNew?: boolean;
  isDeprecated?: boolean;
  isBeta?: boolean;
  tooltip?: string;
};

export enum LLMProvider {
  OpenAI,
  Google,
  Hebbia,
  Anthropic,
  Meta,
}

// ordered by priority and then by descending token limit
export const LLM_CONFIGS: LLM_CONFIG[] = [
  {
    model: "gpt-4o",
    label: "GPT-4o",
    tokenLimit: 128 * 1000,
    provider: LLMProvider.OpenAI,
  },
  {
    model: "o1-preview",
    label: "o1 🍓",
    tokenLimit: 128 * 1000,
    provider: LLMProvider.OpenAI,
    isBeta: true,
    tooltip: "Contact us for early access!",
  },
  {
    model: "o1-mini",
    label: "o1 mini 🍓",
    tokenLimit: 128 * 1000,
    provider: LLMProvider.OpenAI,
    isBeta: true,
    tooltip: "Contact us for early access!",
  },
  {
    model: "gpt-4o-mini",
    label: "GPT-4o mini",
    tokenLimit: 128 * 1000,
    provider: LLMProvider.OpenAI,
    isNew: true,
  },
  {
    model: "claude-3-5-sonnet-20240620",
    label: "Claude 3.5 Sonnet",
    tokenLimit: 200 * 1000,
    isNew: true,
    provider: LLMProvider.Anthropic,
  },
  {
    model: "gpt-4t-hebbia",
    tokenLimit: 128 * 1000,
    label: "GPT-4 Turbo",
    provider: LLMProvider.OpenAI,
  },
  {
    model: "gpt-4-32k-0314",
    tokenLimit: 32 * 1000,
    label: "GPT-4",
    provider: LLMProvider.OpenAI,
  },
  {
    model: "gpt-4-0613",
    label: "GPT-4",
    tokenLimit: 8 * 1000,
    provider: LLMProvider.OpenAI,
  },
  {
    model: "gpt-3.5-turbo-16k-0613",
    label: "GPT-3.5",
    tokenLimit: 16 * 1000,
    provider: LLMProvider.OpenAI,
    isDeprecated: true,
  },
  {
    model: "gpt-3.5-turbo-0613",
    label: "GPT-3.5",
    tokenLimit: 4 * 1000,
    provider: LLMProvider.OpenAI,
    isDeprecated: true,
  },
  {
    model: "gpt-3.5-turbo-instruct",
    label: "GPT-3.5 Instruct",
    tokenLimit: 4 * 1000,
    provider: LLMProvider.OpenAI,
    isDeprecated: true,
  },
  {
    model: "claude-3-haiku-20240307",
    label: "Claude 3 Haiku",
    tokenLimit: 200 * 1000,
    provider: LLMProvider.Anthropic,
  },
  {
    model: "claude-3-opus-20240229",
    label: "Claude 3 Opus",
    tokenLimit: 200 * 1000,
    provider: LLMProvider.Anthropic,
  },
  {
    model: "claude-3-sonnet-20240229",
    label: "Claude 3 Sonnet",
    tokenLimit: 200 * 1000,
    provider: LLMProvider.Anthropic,
  },
  {
    model: "claude-2.1",
    label: "Claude 2.1",
    tokenLimit: 200 * 1000,
    provider: LLMProvider.Anthropic,
    isDeprecated: true,
  },
  {
    model: "claude-2.0",
    label: "Claude 2",
    tokenLimit: 100 * 1000,
    provider: LLMProvider.Anthropic,
    isDeprecated: true,
  },
  {
    model: "claude-instant-1.1",
    label: "Claude 1.1",
    tokenLimit: 100 * 1000,
    provider: LLMProvider.Anthropic,
    isDeprecated: true,
  },
  {
    model: "gemini-15-pro",
    label: "Gemini Pro 1.5",
    tokenLimit: 1000 * 1000,
    provider: LLMProvider.Google,
  },
  {
    model: "gemini-pro",
    label: "Gemini Pro",
    tokenLimit: 32 * 1000,
    provider: LLMProvider.Google,
  },
  {
    model: "gemini-ultra",
    label: "Gemini Ultra",
    tokenLimit: 8 * 1000,
    provider: LLMProvider.Google,
  },
  {
    model: "chat-bison@001",
    label: "PaLM2",
    tokenLimit: 8 * 1000,
    isDeprecated: true,
    provider: LLMProvider.Google,
  },
  {
    model: "text-bison-32k",
    label: "PaLM2-32K",
    tokenLimit: 32 * 1000,
    isDeprecated: true,
    provider: LLMProvider.Google,
  },
  {
    model: "command",
    label: "Cohere Command",
    tokenLimit: 4 * 1000,
    isDeprecated: true,
    provider: LLMProvider.Hebbia,
  },
  {
    model: "command-light",
    label: "Cohere Command Light",
    tokenLimit: 4 * 1000,
    isDeprecated: true,
    provider: LLMProvider.Hebbia,
  },
  {
    model: "meta.llama3-1-405b-instruct-v1:0",
    label: "Meta Llama 405B",
    tokenLimit: 128 * 1000,
    provider: LLMProvider.Meta,
    isBeta: true,
  },
];

type LLMMap = { [key in ModelType]: LLM_CONFIG };

export const LLM_CONFIG_MAP = LLM_CONFIGS.reduce((acc: LLMMap, modelType) => {
  acc[modelType.model] = modelType;
  return acc;
}, {} as LLMMap);

export const O1_MODELS = new Set<ModelType>(["o1-preview", "o1-mini"]);
