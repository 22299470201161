import React, { ElementRef, useEffect } from "react";
import { classNames } from "core";
import { getMenuItemIndex, useMenu } from "./MenuContext";
import ListItem, { ListItemProps } from "./ListItem";
import { useChildComponentId } from "../../hooks/useChildComponentId";

export type MenuItemProps<T extends React.ElementType> = {
  onClick: (event: React.MouseEvent<T, MouseEvent>) => void;
} & ListItemProps<T>;

const MenuItem = React.forwardRef(
  <T extends React.ElementType>(
    { className, onClick, ...rest }: MenuItemProps<T>,
    parentRef: React.Ref<React.ElementRef<T>>
  ) => {
    const internalRef = React.useRef(null);

    // Since this component needs a ref, and we also want to accept a ref prop,
    // we need to pass the internal ref to the component and use this hook below to forward
    // the ref to the passed ref prop
    React.useImperativeHandle(
      parentRef,
      () => internalRef.current as ElementRef<T>
    );

    const {
      registerMenuItem,
      getMenuItemProps,
      focusedIndex,
      setFocusedIndex,
      parentId,
    } = useMenu();

    useEffect(() => {
      registerMenuItem(internalRef);
    }, [registerMenuItem]);

    const {
      onClick: menuOnClick,
      className: classNameFromMenu,
      ...propsFromMenu
    } = getMenuItemProps(internalRef);

    const index = getMenuItemIndex(internalRef);
    const isFocused = focusedIndex === index;
    const id = useChildComponentId(parentId, "MenuItem", index);

    return (
      <ListItem
        {...propsFromMenu}
        {...rest}
        className={classNames(
          isFocused && "bg-gray-100",
          classNameFromMenu,
          className
        )}
        aria-selected={isFocused}
        id={id}
        onClick={(e: MouseEvent) => {
          menuOnClick?.(e);
          onClick?.(e);
        }}
        onMouseEnter={() => {
          setFocusedIndex(index);
        }}
        onMouseLeave={() => {
          setFocusedIndex(-1);
        }}
        ref={internalRef}
      />
    );
  }
);

export default MenuItem;
