import React, { ReactNode } from "react";
import {
  DocumentsParams,
  SearchType,
  SharepointResourceType,
  SourceType,
} from "./types";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { FiltersConfig } from "source/constants/filters";
import SharePointIcon from "assets/mimes/sharepoint.png";
import Image from "next/image";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { SvgIconComponent } from "@mui/icons-material";

export type SourceConfigMap = {
  [key in SourceType]: SourceConfig;
};

export type SourceConfig = {
  name: string;
  value: SourceType;
  Icon?: SvgIconComponent;
  image?: ReactNode;
};

export const SOURCE_TYPE_CONFIG_MAPPING: SourceConfigMap = {
  [SourceType.ORGANIZATION]: {
    name: "Organization",
    Icon: CorporateFareIcon,
    value: SourceType.ORGANIZATION,
  },
  [SourceType.PUBLIC_COMPANIES]: {
    name: "Public companies",
    Icon: AccountBalanceIcon,
    value: SourceType.PUBLIC_COMPANIES,
  },
  [SourceType.PERSONAL_UPLOADS]: {
    name: "Personal uploads",
    Icon: PersonOutlineIcon,
    value: SourceType.PERSONAL_UPLOADS,
  },
  [SourceType.SHARED_WITH_ME]: {
    name: "Shared with me",
    Icon: PersonAddAltIcon,
    value: SourceType.SHARED_WITH_ME,
  },
  [SourceType.SHAREPOINT]: {
    name: "SharePoint",
    image: (
      <Image src={SharePointIcon} width={15} height={15} alt="SharePoint" />
    ),
    value: SourceType.SHAREPOINT,
  },
  [SourceType.AZURE_FILE_SHARE]: {
    name: "Azure File Share",
    image: (
      <Image
        src="https://hebbia-assets-prod.s3.us-west-2.amazonaws.com/lock.png"
        width={15}
        height={15}
        alt="Azure File Share"
        priority={true}
      />
    ),
    value: SourceType.AZURE_FILE_SHARE,
  },
  [SourceType.S3]: {
    name: "AWS S3",
    image: (
      <Image
        src="https://hebbia-assets-prod.s3.us-west-2.amazonaws.com/s3.png"
        width={15}
        height={15}
        alt="S3"
        priority={true}
      />
    ),
    value: SourceType.S3,
  },
  [SourceType.BOX]: {
    name: "Box",
    image: (
      <Image
        src="https://hebbia-assets-prod.s3.us-west-2.amazonaws.com/box.png"
        width={15}
        height={15}
        alt="Box"
        priority={true}
      />
    ),
    value: SourceType.BOX,
  },
};

export const MOCK_INTEGRATIONS_SOURCE_TAB_MAPPING: {
  [SourceType.BOX]: SourceConfig;
} = {
  [SourceType.BOX]: {
    name: "Box",
    image: (
      <Image
        src="https://hebbia-assets-prod.s3.us-west-2.amazonaws.com/box.png"
        width={15}
        height={15}
        alt="Box"
        priority={true}
      />
    ),
    value: SourceType.BOX,
  },
};

export const SEARCH_TYPE_CONFIG_MAPPING = {
  [SearchType.COMPANY_SEARCH]: {
    name: "Public Companies",
  },
  [SearchType.FILE_NAMES]: {
    name: "File names and metadata",
  },
  [SearchType.FILE_NAMES_BM25]: {
    name: "File names and metadata",
  },
  [SearchType.SEMANTIC_SIMILARITY]: {
    name: "Semantic similarity",
  },
};

export const DEFAULT_SEARCH_REPOS_LIMIT = 3; // Default num repos
export const DEFAULT_SEARCH_LIMIT = 4; // Default num docs / folders
export const DEFAULT_SEARCH_OFFSET = 0;
export const EXPANDED_SEARCH_LIMIT = 50;

export const DEFAULT_GET_DOCUMENTS_PARAMS: DocumentsParams = {
  offset: DEFAULT_SEARCH_OFFSET,
  limit: DEFAULT_SEARCH_LIMIT,
  search_type: undefined, // only persist search types to BE, undefined = filters
  source_type: SourceType.ORGANIZATION,
  query: "",
  filters: undefined,
  sort_order: "relevance",
};

export const EXCLUDED_META_FILTERS = [
  FiltersConfig.REPO_ID_FILTER_KEY,
  FiltersConfig.DOC_ID_FILTER_KEY,
  FiltersConfig.DOC_TITLE_FILTER_KEY,
  FiltersConfig.INTEGRATION_FILTER_KEY,
  FiltersConfig.MIME_FILTER_KEY,
  FiltersConfig.FILTER_KEY_DATE,
];

export const DOCUMENT_LIMIT = 200;
export const GIGA_DOCUMENT_LIMIT = 1000;

export const DEFAULT_PUBLIC_SOURCE_TYPE_ORGS: string[] = [];
export const MOCK_INTEGRATION_SOURCE_TAB_ORGS: string[] = [];
export const DISABLE_SHAREPOINT_ORGS: string[] = [];
export const ORG_HIDDEN_REPO_MAPPING: Record<string, string[]> = {};

export const SharepointResourceTypeConfigMapping = {
  [SharepointResourceType.DRIVES]: {
    label: "Drives",
  },
  [SharepointResourceType.SITES]: {
    label: "Sites",
  },
  [SharepointResourceType.SHARED_WITH_ME]: {
    label: "Shared With Me",
  },
};
