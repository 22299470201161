import { useUser } from "@auth0/nextjs-auth0/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "source/api/axios";
import { getUser, setUser } from "source/redux/user";
import { clearAuthToken, setAuthToken } from "./localStorage";
import { SLACK_WEBHOOK_URL } from "source/constants";
import fetchAccessToken from "./fetchAccessToken";
import { sendSlackAlert } from "source/utils/slack";
import { prefetchUserRepos } from "source/api/repos/useQueryRepos";

export const useClientAuth = () => {
  const dispatch = useDispatch();
  // User is stored in redux, auth token is automatically added to axios factory for proper authentication w our BE
  const user = useSelector(getUser);
  const { user: authUser, isLoading } = useUser();

  // If is a new session, we need to display loading before redirecting to /login
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get("/v2/users")
      .then(({ data }) => {
        dispatch(setUser(data.user));
      })
      .catch((error) => {
        setError(error);
      });

    // Lets do one more call to access token to rehydrate cached token.
    fetchAccessToken().then(({ accessToken }) => {
      if (accessToken) setAuthToken(accessToken);
      else clearAuthToken();
    });

    // Prefetch user repos, everyone needs them!
    prefetchUserRepos();
  }, []);

  useEffect(() => {
    // Auth user is the Auth0 user profile
    // current User is the Hebbia User Profile
    // If at any point these don't match, immediately log the user out and send and alert

    // If mocking users, comment out this user effect
    if (
      authUser &&
      user &&
      authUser.email?.trim().toLowerCase() !== user.email.trim().toLowerCase()
    ) {
      sendSlackAlert(
        `Authentication Error. Auth0 user ${authUser.email} with id ${authUser.sub} does not match Hebbia User ${user.email} with id ${user.id} credentials.`,
        SLACK_WEBHOOK_URL
      );
      window.location.href = "/api/auth/logout";
    }
  }, [authUser, user]);

  const authenticated = !!(user && !error);
  return { authenticated, loading: isLoading, authUser };
};
