import React from "react";
import { ReactNode } from "react";
import Image from "next/image";
import {
  LLM_CONFIG_MAP,
  LLM_CONFIGS,
  LLMProvider,
  ModelType,
} from "source/constants/llms";
import openaiLogo from "../../../assets/integrations/openai-logo.svg";
import anthropicLogo from "../../../assets/integrations/anthropic-logo.svg";
import googleLogo from "../../../assets/integrations/google-logo.svg";
import logo from "../../../assets/logoAlt.svg";
import metaLogo from "../../../assets/integrations/meta-logo.svg";
import { getFeatureFlag } from "../ldClient";
import { DEFAULT_TOOL_PARAM_MODEL } from "source/constants";
import logger from "../logger";

export const getFindToolModelLabel = (model: ModelType) => {
  const llm = LLM_CONFIG_MAP[model];
  if (!llm) return "";
  return llm.label;
};

export const getFindToolModelTokenLabel = (model: ModelType): string => {
  const llm = LLM_CONFIG_MAP[model];
  if (!llm) return "";
  const abbreviatedTokenCount = Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(llm.tokenLimit);
  return `${abbreviatedTokenCount} token limit`;
};

export const getFindToolModelHumanReadableName = (model: ModelType): string =>
  `${getFindToolModelLabel(model)} (${getFindToolModelTokenLabel(model)})`;

export const getFindToolModelLogo = (model: ModelType): ReactNode => {
  const llm = LLM_CONFIG_MAP[model];
  if (!llm) return null;

  switch (llm.provider) {
    case LLMProvider.OpenAI:
      return (
        <Image
          src={openaiLogo}
          alt="OpenAI"
          height="16"
          width="16"
          style={{
            width: "16",
            height: "16",
          }}
        />
      );
    case LLMProvider.Anthropic:
      return (
        <Image
          src={anthropicLogo}
          alt="Anthropic"
          height="16"
          width="16"
          style={{
            width: "16",
            height: "16",
          }}
        />
      );
    case LLMProvider.Google:
      return (
        <Image
          src={googleLogo}
          alt="Google"
          height="16"
          width="16"
          style={{
            width: "16",
            height: "16",
          }}
        />
      );
    case LLMProvider.Hebbia:
      return (
        <Image
          src={logo}
          alt="Hebbia"
          height="16"
          width="16"
          style={{
            width: "16",
            height: "16",
          }}
        />
      );
    case LLMProvider.Meta:
      return (
        <Image
          src={metaLogo}
          alt="Meta"
          height="16"
          width="16"
          style={{
            width: "16",
            height: "16",
          }}
        />
      );
  }
};

export const getDefaultModel = () => {
  const defaultModel = getFeatureFlag(
    "matrixDefaultFindToolModel",
    DEFAULT_TOOL_PARAM_MODEL
  ) as ModelType;
  const blacklistedModels = getFeatureFlag("reportsModelOptionsBlacklist", []);
  const modelPreferences: ModelType[] = [
    defaultModel,
    "gpt-4t-hebbia",
    "gpt-4-32k-0314",
    "gpt-3.5-turbo-16k-0613",
    "claude-2.0",
    "gpt-4-0613",
    "text-bison-32k",
  ];

  const availableModel = modelPreferences.find(
    (model) => !blacklistedModels?.includes(model)
  );

  if (availableModel) {
    return availableModel;
  } else {
    logger.error("All available default models are blacklisted");
    return "gpt-3.5-turbo-0613" as ModelType;
  }
};

export const getModelOptions = () => {
  const defaultModel = getDefaultModel();
  const blacklistedModels = getFeatureFlag("reportsModelOptionsBlacklist", []);
  return LLM_CONFIGS.filter(
    ({ model }) => !blacklistedModels?.includes(model)
  ).map(({ model, isNew, isDeprecated, isBeta, tooltip }) => ({
    id: model,
    isNew,
    name: getFindToolModelHumanReadableName(model),
    icon: getFindToolModelLogo(model),
    isDefault: model === defaultModel,
    isDeprecated,
    isBeta,
    tooltip,
  }));
};
