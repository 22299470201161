import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { createLogger } from "redux-logger";
import { buildReducer, BuildReduxType } from "./build";
import { downloadBuildReducer, DownloadBuildReduxType } from "./downloadBuild";
import {
  findoxHoldingsReducer,
  FindoxHoldingsReduxType,
} from "./findoxHoldings";
import { listenerMiddleware } from "./listenerMiddleware";
import { metaReducer, MetaReduxType } from "./meta";
import { orgReducer, OrgReduxType } from "./organization";
import { PinReduxType, pinsReducer } from "./pin";
import { reportReducer, ReportReduxType } from "./matrix";
import { searchReducer, SearchReduxType } from "./search";
import { searchHistoryReducer, SearchHistoryReduxType } from "./searchHistory";
import { sidebarReducer, SidebarReduxType } from "./sidebar";
import { tableStatusReducer, TableStatusReduxType } from "./tables";
import { uiReducer, UIReduxType } from "./ui";
import { uploadReducer, UploadReduxType } from "./upload";
import { repoStatusReducer, RepoStatusReduxType } from "./repoStatus";
import { userReducer, UserReduxType } from "./user";
import { activityFeedReducer, ActivityFeedReduxType } from "./activityFeed";
import {
  singleDocSearchReducer,
  SingleDocSearchReduxType,
} from "./singleDocSearch";
import LogRocket from "logrocket";
import { slackAlertReducer, slackAlertReduxType } from "./slackAlert";
import { activeBuildReducer, ActiveBuildReduxType } from "./activeBuilds";
import { docTablesReducer, DocTablesReduxType } from "./docTables";
import {
  searchDropdownReducer,
  SearchDropdownReduxType,
} from "./searchDropdown";
import { filtersReducer, FilterTypeReduxType } from "./filters";
import { GPTAnswerPinReduxType, gptAnswerPinsReducer } from "./gptAnswerPin";
import { gtdAnswerReducer, GtdReduxAnswerType } from "./gtdAnswer";
import { debugFlagReducer, DebugFlagReduxType } from "./debug";
import {
  downloadExactMatchSearchReducer,
  DownloadExactMatchSearchReduxType,
} from "./downloadExactMatchSearch";
import { chatReducer, ChatReduxType } from "./chat";
import { alertEditingReducer, AlertEditingType } from "./alerts";
import { bannersReducer, BannersReduxType } from "./banners";
import { advancedTargetReducer, AdvancedTargetReduxType } from "./advanced";
import { addDocsReducer, AddDocumentsReduxType } from "./addDocs";
import { useDispatch } from "react-redux";
import { fastBuildReducer, FastBuildReduxType } from "./fastBuild";
import { matrixChatReducer, MatrixChatReduxType } from "./matrixChat";
import { documentListReducer, DocumentListReduxType } from "./documentList";
import { docViewerReducer, DocViewerReduxType } from "./docViewer";

const IS_LOGGER_ENABLED = false; //process.env.NODE_ENV === "development";

export type ReduxState = {
  addDocs: AddDocumentsReduxType;
  build: BuildReduxType;
  activeBuilds: ActiveBuildReduxType;
  singleDocSearch: SingleDocSearchReduxType;
  downloadBuild: DownloadBuildReduxType;
  downloadExactMatchSearch: DownloadExactMatchSearchReduxType;
  fastBuild: FastBuildReduxType;
  filters: FilterTypeReduxType;
  findoxHoldings: FindoxHoldingsReduxType;
  meta: MetaReduxType;
  organization: OrgReduxType;
  pins: PinReduxType;
  gptAnswerPins: GPTAnswerPinReduxType;
  gtd: GtdReduxAnswerType;
  reports: ReportReduxType;
  search: SearchReduxType;
  searchHistory: SearchHistoryReduxType;
  sidebar: SidebarReduxType;
  slackAlert: slackAlertReduxType;
  tableStatus: TableStatusReduxType;
  docTables: DocTablesReduxType;
  ui: UIReduxType;
  users: UserReduxType;
  upload: UploadReduxType;
  repoStatus: RepoStatusReduxType;
  activityFeed: ActivityFeedReduxType;
  searchDropdown: SearchDropdownReduxType;
  debugFlag: DebugFlagReduxType;
  chat: ChatReduxType;
  advancedTarget: AdvancedTargetReduxType;
  alertEditingProps: AlertEditingType;
  banners: BannersReduxType;
  matrixChat: MatrixChatReduxType;
  documentList: DocumentListReduxType;
  docViewer: DocViewerReduxType;
};

export const rootReducer = combineReducers({
  addDocs: addDocsReducer,
  build: buildReducer,
  activeBuilds: activeBuildReducer,
  singleDocSearch: singleDocSearchReducer,
  downloadBuild: downloadBuildReducer,
  downloadExactMatchSearch: downloadExactMatchSearchReducer,
  fastBuild: fastBuildReducer,
  findoxHoldings: findoxHoldingsReducer,
  filters: filtersReducer,
  meta: metaReducer,
  organization: orgReducer,
  pins: pinsReducer,
  gptAnswerPins: gptAnswerPinsReducer,
  gtd: gtdAnswerReducer,
  reports: reportReducer,
  search: searchReducer,
  searchHistory: searchHistoryReducer,
  sidebar: sidebarReducer,
  slackAlert: slackAlertReducer,
  tableStatus: tableStatusReducer,
  docTables: docTablesReducer,
  ui: uiReducer,
  users: userReducer,
  upload: uploadReducer,
  repoStatus: repoStatusReducer,
  activityFeed: activityFeedReducer,
  searchDropdown: searchDropdownReducer,
  debugFlag: debugFlagReducer,
  chat: chatReducer,
  advancedTarget: advancedTargetReducer,
  alertEditingProps: alertEditingReducer,
  banners: bannersReducer,
  matrixChat: matrixChatReducer,
  documentList: documentListReducer,
  docViewer: docViewerReducer,
});

const logger = createLogger();

// From https://www.quintessential.gr/blog/development/how-to-integrate-redux-with-next-js-and-ssr

const hebbiaMiddleware = [
  LogRocket.reduxMiddleware({
    actionSanitizer: (action) => {
      if (action.type === "upsertMessage") {
        return null;
      }
      return action;
    },
  }),
  ...(IS_LOGGER_ENABLED ? [logger] : []),
  ...(listenerMiddleware ? [listenerMiddleware.middleware] : []),
];

export const setupStore = (preloadedState?: any) =>
  configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(hebbiaMiddleware);
    },
  });

let store: ReturnType<typeof setupStore>;
const initializeStore = (preloadedState: any) => {
  // If a store has been created and CSR, use it
  if (store && typeof window !== "undefined") return store;

  // Always create a new store if SSR
  if (preloadedState) store = setupStore(preloadedState);
  else store = setupStore(undefined);
  return store;
};

export const useStore = (preloadedState) => initializeStore(preloadedState);
export const useAppDispatch: () => typeof store.dispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  any
>;
