import { FilterModel, SortModelItem } from "ag-grid-community";
import { DocumentType, IDocumentMime } from "source/Types";

export interface DocumentRow extends FolderItem {
  filePath: string[];
}

export type DocumentListUser = {
  id: string;
  email: string;
  name: string;
  picture: string;
};

export type DocumentListUserPermissions = {
  user_id: string;
  role: "owner" | "editor" | "viewer";
};

export type DocumentList = {
  id: string;
  name: string;
  color: string | null;
  created_at: string;
  updated_at: string;
  owner: DocumentListUser;
  user_permissions: DocumentListUserPermissions;
};

export type DocumentListResponseV0 = {
  document_list_id: string;
  sources: DocumentListSource[];
  name: string;
  description: string | null;
  icon: string | null;
  color: string | null;
};

export type DocumentListV0 = Omit<
  DocumentListResponseV0,
  "document_list_id"
> & {
  id: string;
};

export type DocumentListSource = {
  source_id: string;
  source_name: string;
  integration_type: string; // todo: connect to fastbuild integration enum
  enabled: string;
  schedule: Record<string, any>; // todo: better define the schedule
};

export type DocumentListDisplayDataType = {
  id: string;
  name: string;
  icon: string | null;
  color: string | null;
};

export type DocumentListDocument = Pick<
  DocumentType,
  "id" | "timestamp" | "mime" | "title"
> & {
  document_list_id: string;
};

export type DocumentsListStatusResponse = {
  completed_count: number;
  total_count: number;
};

export enum FolderItemType {
  FOLDER = "folder",
  DOCUMENT = "document",
}

export type FolderItem = {
  id: string;
  name: string;
  parent_id: string | null;
  type: FolderItemType;
  path: string;
  timestamp?: string;
  document_id?: string;
  mime?: IDocumentMime;
};

export type GetChildrenResponse = {
  items: FolderItem[];
  total_count: number;
  has_more: boolean;
  total_document_count: number;
};

export type CreateDocumentListRequest = {
  name: string;
  color: string;
};

export type GetDocumentsRequest = {
  sorts: SortModelItem[];
  filters: FilterModel | null;
  offset: number;
  limit: number;
};

export type MoveFolderRequest = {
  id: string;
  target_folder_id: string | null;
  type: FolderItemType;
};

export type UploadFolderRequest = {
  documentListId: string;
  files: File[];
  rootFolderId?: string;
  ignoreRoot?: boolean;
};

export type UpdateFolderParams = {
  id: string;
  name: string;
};

export type CreateFolderRequest = {
  name: string;
  parent_id?: string;
};

export type DocumentStatusCount = {
  completed: number;
  total: number;
};

export type MoveItemToDocumentList = {
  id: string;
  target_document_list_id: string;
};
