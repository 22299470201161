import {
  FastBuildResponse,
  Report,
  ReportInfo,
  PromptListType,
  BulkRunStatusResponse,
  BulkRunStatusParams,
  DuplicateResponse,
  ReportVersionsResponse,
  MatrixCreateGigaResult,
  IngestFilesResponse,
  FeedbackPayload,
  AutoTitleReportParams,
  MatrixListMetadata,
  UserTemplateMetadata,
} from "source/components/matrix/types/reports.types";
import axios from "../axios";
import {
  GetDocumentsPayload,
  MatrixDocSearchResult,
} from "source/components/matrix/menu/AddDocumentsModal/shared/types";
import {
  AddMatrixUserParams,
  MatrixMetadataResponse,
  MatrixLeaseResponse,
  MatrixShareSettings,
  MatrixUsersResponse,
  RemoveMatrixUserParams,
  UpdateShareSettingsParams,
  GeneratePromptResponse,
  EditMatrixUserParams,
  ReportAndCellsResponse,
  SuggestColumnsResponse,
  SuggestChartsResponse,
  TemplateMetadataParams,
  GeneratePromptRequest,
} from "./types";
import {
  SheetChildrenCountResponse,
  SheetDocChildrenCountParams,
  SheetDocChildrenParams,
  SheetDocChildrenResponse,
} from "../docs/types";
import {
  CellContent,
  FindResultType,
} from "source/components/matrix/types/cells.types";
import { PromptResult } from "source/components/matrix/types/tools.types";
import { DocumentListResponseV0 } from "source/types/document-list/documentList.types";
import { MatrixShareRequestsResponse } from "source/types/matrix/sharing.types";

export type FetchCellContentParams = {
  reportId: string;
  cellIds: string[];
  prune: boolean; // Prunes the cell content to only what is needed by FE in reports answer cells
  isGiga: boolean;
  tabId?: string;
};

// This API is called Reports in the frontend to be consistent with our
// naming conventions but hits the sheets service in the backend
export const Reports = {
  createWithDocs: (
    report: Report,
    orgId: string,
    documentIds?: string[]
  ): Promise<Report> => {
    if (documentIds && documentIds.length > 0) {
      return axios
        .post(`/sheets/create-with-docs?org_id=${orgId}`, {
          sheet_meta: report,
          doc_ids: documentIds,
        })
        .then(({ data }) => data);
    }
    return axios
      .post(`/sheets?org_id=${orgId}`, report)
      .then(({ data }) => data);
  },
  duplicate: (id: string, name: string, orgId?: string) =>
    axios
      .post<DuplicateResponse>(`/sheets/${id}/duplicate`, {
        name,
        org_id: orgId,
      })
      .then(({ data }) => data),
  duplicateSubset: (
    id: string,
    name: string,
    orgId: string,
    docIds: string[]
  ) =>
    axios
      .post<DuplicateResponse>(`/sheets/${id}/duplicate-subset`, {
        name,
        org_id: orgId,
        doc_ids: docIds,
      })
      .then(({ data }) => data),
  shareAsTemplate: (
    id: string,
    name: string,
    orgId: string,
    invitee_email: string
  ) =>
    axios.post<string>(`/sheets/${id}/template/share`, {
      name,
      org_id: orgId,
      invitee_email,
    }),
  restore: (id: string, name: string, versionId: string, orgId?: string) =>
    axios
      .post<Report>(`/sheets/${id}/restore`, {
        name,
        org_id: orgId,
        version_id: versionId,
      })
      .then(({ data }) => data),
  getReports: (): Promise<ReportInfo[]> =>
    axios.get(`/sheets`).then(({ data }) => data),
  getReportById: ({
    reportId,
    versionId,
    signal,
  }: {
    reportId: string;
    versionId?: string;
    signal?: AbortSignal;
  }): Promise<Report> =>
    axios
      .get(`/sheets/${reportId}`, {
        params: { version_id: versionId },
        signal,
      })
      .then(({ data }) => data),
  getReportAndCells: ({
    reportId,
    versionId,
    signal,
  }: {
    reportId: string;
    versionId?: string;
    signal?: AbortSignal;
  }): Promise<ReportAndCellsResponse> =>
    axios
      .get(`/sheets/${reportId}`, {
        params: { version_id: versionId, output_format: "v2" },
        signal,
      })
      .then(({ data }) => data),
  getReportVersionsById: (id: string): Promise<ReportVersionsResponse> =>
    axios
      .get<ReportVersionsResponse>(`/sheets/${id}/versions`)
      .then(({ data }) => data),
  bookmarkReportById: (id: string, bookmarked: boolean) =>
    axios.post(`/sheets/${id}/user_sheet/update`, { bookmarked: bookmarked }),
  deleteReportById: (id: string) => axios.delete(`/sheets/${id}`),
  deleteReportBatch: (ids: string[]) =>
    axios.delete(`/sheets`, { data: { sheet_ids: ids } }),
  autoTitleReportName: (
    id: string,
    params: AutoTitleReportParams
  ): Promise<{ name: string | null }> =>
    axios
      .post(`/sheets/${id}/generate-sheet-name`, params)
      .then(({ data }) => data),
  renameReportById: (id: string, name: string): Promise<Report> =>
    axios.post(`/sheets/${id}/update`, { name }).then(({ data }) => data),
  buildBulkReport: (id: string, orgId) =>
    axios
      .post(`/sheets/build-large`, {
        sheet_id: id,
        org_id: orgId,
      })
      .then(({ data }) => data),
  fetchCellContent: async ({
    reportId,
    cellIds,
    prune,
    isGiga,
  }: FetchCellContentParams): Promise<CellContent[]> => {
    // dedupe cell ids
    const dedupedCellIds = Array.from(new Set(cellIds));
    return axios
      .post(`/sheets/batch-cell-content?sheet_id=${reportId}`, {
        cell_ids: dedupedCellIds,
        prune,
        is_giga: isGiga,
      })
      .then(({ data }) => data);
  },
  fetchCellWhy: async ({
    reportId,
    cellId,
  }: {
    reportId: string;
    cellId: string;
  }): Promise<{ why: string }> => {
    return axios
      .get(`/sheets/prompt/explain-cell?sheet_id=${reportId}&cell_id=${cellId}`)
      .then(({ data }) => data);
  },
  fetchFormattedCellContent: async ({
    reportId,
    cellIds,
    prune,
    isGiga,
    tabId,
  }: FetchCellContentParams): Promise<any[]> => {
    // For formatted cells on a giga matrix
    const dedupedCellIds = Array.from(new Set(cellIds));
    return axios
      .post(`/sheets/formatted-batch-cell-content?sheet_id=${reportId}`, {
        cell_ids: dedupedCellIds,
        prune,
        is_giga: isGiga,
        tab_id: tabId,
      })
      .then(({ data }) => data);
  },
  uploadFilesFastBuild: async (
    reportId: string,
    files: Array<File>
  ): Promise<FastBuildResponse> => {
    const form = new FormData();

    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      if (f) form.append("files", f);
    }

    return axios
      .post(`/sheets/${reportId}/fast-build`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => data);
  },
  ingestLocalFiles: async (
    reportId: string,
    orgId: string,
    files: Array<File>
  ): Promise<IngestFilesResponse> => {
    const form = new FormData();

    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      if (f) form.append("files", f);
    }

    return axios
      .post(
        `/sheets/build/ingest-local-docs?sheet_id=${reportId}&org_id=${orgId}`,
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => data);
  },
  editCell: (sheet_id: string, cell_id: string, result: FindResultType) =>
    axios.post(`/sheets/${sheet_id}/edit-cell`, { cell_id, result }),
  getSheetsBuildStatus: (
    sheet_id: string,
    params: BulkRunStatusParams
  ): Promise<BulkRunStatusResponse> =>
    axios
      .get(`/sheets/${sheet_id}/sheets-build`, {
        params,
      })
      .then(({ data }) => data)
      .catch((error) => console.error(error)),
  getPromptListsByOrgId: (orgId: string): Promise<PromptListType[]> =>
    axios
      .get(`/sheets/prompts/org/${orgId}`)
      .then(({ data }) => data)
      .catch((error) => console.error(error)),
  getPublicPromptLists: (): Promise<PromptListType[]> =>
    axios
      .get(`/sheets/prompts`)
      .then(({ data }) => data)
      .catch((error) => console.error(error)),
  cellUpvoteDownvote: (
    payload: FeedbackPayload,
    reportId: string,
    versionId: string
  ) => {
    axios
      .post(`/sheets/feedback`, {
        payload,
        report_id: reportId,
        version_id: versionId,
      })
      .catch((err) => {
        console.error("Unable to submit feedback", err);
      });
  },
  getDocuments: (
    orgId: string,
    payload: GetDocumentsPayload
  ): Promise<MatrixDocSearchResult[]> =>
    axios
      .post(`/sheets/docs/search?org_id=${orgId}`, payload)
      .then(({ data }) => data)
      .catch((error) => console.error(error)),
  getPromptsByOrgId: (org_id): Promise<PromptResult[]> =>
    axios.get(`/sheets/prompt/${org_id}`).then(({ data }) => data),
  createGigaMatrix: (
    reportId: string,
    orgId: string
  ): Promise<MatrixCreateGigaResult> =>
    axios
      .post(`/sheets/${reportId}/giga`, { org_id: orgId })
      .then(({ data }) => data)
      .catch((error) => console.error(error)),
  addMatrixUser: ({ matrixId, email, role }: AddMatrixUserParams) =>
    axios
      .post(`/sheets/${matrixId}/add-user`, {
        email: email,
        role: role,
      })
      .then(({ data }) => data),
  setMatrixUserRole: ({ matrixId, userId, role }: EditMatrixUserParams) =>
    axios
      .post(`/sheets/${matrixId}/edit-user`, {
        user_id: userId,
        role: role,
      })
      .then(({ data }) => data),
  removeMatrixUser: ({ matrixId, userId }: RemoveMatrixUserParams) =>
    axios
      .delete(`/sheets/${matrixId}/remove-user/${userId}`)
      .then(({ data }) => data),
  getMatrixUsers: (matrixId: string): Promise<MatrixUsersResponse> =>
    axios.get(`/sheets/${matrixId}/users`).then(({ data }) => {
      return data.users.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
    }),
  getChildren: (
    orgId: string,
    params: SheetDocChildrenParams
  ): Promise<SheetDocChildrenResponse> =>
    axios
      .post(`/sheets/docs/children?org_id=${orgId}`, params)
      .then(({ data }) => data),
  getChildrenCount: (
    orgId: string,
    params: SheetDocChildrenCountParams
  ): Promise<SheetChildrenCountResponse> =>
    axios
      .post(`/sheets/docs/count_children?org_id=${orgId}`, params)
      .then(({ data }) => data),
  createMatrixShareSettings: (
    matrixId: string,
    permissionLevel: string
  ): Promise<MatrixShareSettings> =>
    axios
      .post(`/sheets/share-settings/${matrixId}`, {
        share_settings: permissionLevel,
      })
      .then(({ data }) => data),
  getMatrixShareSettings: (matrixId: string): Promise<MatrixShareSettings> =>
    axios.get(`/sheets/share-settings/${matrixId}`).then(({ data }) => {
      return {
        id: data.id,
        matrixId: data.matrix_id,
        shareSettings: data.share_settings,
      };
    }),
  updateMatrixShareSettings: ({
    matrixId,
    permissionLevel,
    createIfMissing,
  }: UpdateShareSettingsParams): Promise<MatrixShareSettings> =>
    axios
      .put(`/sheets/share-settings/${matrixId}`, {
        share_settings: permissionLevel,
        create_if_missing: createIfMissing,
      })
      .then(({ data }) => data),
  deleteMatrixShareSettings: (matrixId: string) =>
    axios.delete(`/sheets/share-settings/${matrixId}`).then(({ data }) => data),
  getMatrixMetadata: (matrixId: string): Promise<MatrixMetadataResponse> =>
    axios.get(`/sheets/${matrixId}/metadata`).then(({ data }) => data),
  getMatrixLease: (matrixId: string): Promise<MatrixLeaseResponse> =>
    axios.get(`/sheets/${matrixId}/lease`).then(({ data }) => data),
  claimMatrixLease: (matrixId: string, leaseId: string) =>
    axios.post(`/sheets/${matrixId}/claim/${leaseId}`),
  relinquishMatrixLease: (matrixId: string, leaseId: string) =>
    axios.post(`/sheets/${matrixId}/relinquish/${leaseId}`),
  generatePrompt: (
    request: GeneratePromptRequest
  ): Promise<GeneratePromptResponse> =>
    axios
      .post(`/sheets/prompt/generate-prompt`, {
        user_input: request.userInput,
        sheet_id: request.sheetId,
        output_type: request.outputType,
        output_format: request.outputFormat,
      })
      .then(({ data }) => data),
  generatePromptWithReasoning: (
    matrixId: string,
    field: string,
    fieldType: string,
    fieldReasoning: string
  ): Promise<GeneratePromptResponse> =>
    axios
      .post(`/sheets/prompt/generate-prompt-with-reasoning`, {
        sheet_id: matrixId,
        field: field,
        field_type: fieldType,
        field_reasoning: fieldReasoning,
      })
      .then(({ data }) => data),
  getMatrixListMetadata: (): Promise<MatrixListMetadata> =>
    axios.get(`/sheets/user-metadata`).then(({ data }) => data),
  suggestColumns: (
    matrixId: string,
    searchTerm?: string,
    skipDocRetrieval?: boolean
  ): Promise<SuggestColumnsResponse> =>
    axios
      .post(`/sheets/prompt/suggest-columns`, {
        sheet_id: matrixId,
        search_term: searchTerm,
        skip_doc_retrieval: skipDocRetrieval,
      })
      .then(({ data }) => data),
  getDocumentListsV0: (orgId?: string): Promise<DocumentListResponseV0[]> =>
    axios.get(`/sheets/document-lists/${orgId ?? ""}`).then(({ data }) => data),
  getUserTemplateMetadata: async (): Promise<UserTemplateMetadata> => {
    try {
      const response = await axios.get("/sheets/user-template-metadata");
      return response.data?.result ?? {};
    } catch (error) {
      console.error(error);
      return {};
    }
  },
  setUserTemplateMetadata: async (params: TemplateMetadataParams) => {
    const response = await axios.post("/sheets/user-template-metadata", params);
    return response.data;
  },
  getSharingRequests: async (
    matrixId: string
  ): Promise<MatrixShareRequestsResponse> => {
    const response = await axios.get(
      `/sheets/share-settings/${matrixId}/requests`
    );
    return response.data;
  },
  createSharingRequest: async (matrixId: string) => {
    const response = await axios.post(
      `/sheets/share-settings/${matrixId}/request`
    );
    return response.data;
  },
};
