import { useDispatch } from "react-redux";
import { setRepoNumericFilters, setRepoStrFilters } from "source/redux/filters";
import {
  cancelControllers,
  setKeywordResponse,
  setKeywordResponseLoading,
  setKeywordSearchError,
  setSearchAutoToggled,
  setSearchError,
  setSearchResponse,
  setSearchResponseLoading,
  setTasks,
  setTasksToDisplay,
} from "source/redux/search";
import { clearAllSearchStates, setDateSelected } from "source/redux/sidebar";
import {
  setDocModalVisible,
  setSearchNoDocsVisible,
  setSearchQuery,
} from "source/redux/ui";
import { QueryParams, useGetRouter } from "./useGetRouter";
import {
  addReportFilter,
  clearAllReportFilterSectionsValues,
  clearRelevantResults,
  getFilterSearchKey,
  getReportFilterKey,
  setNoDocsFilters,
  setTargetAppliedFilters,
} from "source/redux/advanced";
import { DOCUMENT_SECTION_TITLE } from "source/components/gigabar/components/FileSection";
import { ReportFilter, ReportFilterOperation } from "source/Types";
import { useFeatureFlag } from "./useFeatureFlag";

/**
 * Hook is for global naving. Use for all router pushes
 */
export const useGlobalNavigator = () => {
  const dispatch = useDispatch();
  const { router } = useGetRouter();
  const { clearUserTargetState, clearReduxSearchState } =
    useSearchStateManager();
  const flaggedHomePage = useFeatureFlag("homepage");
  const homepage = flaggedHomePage ? `/${flaggedHomePage}` : "/matrix";

  /**
   * Wrapper around router.push. Does a shallow push, upserting prev router.query with new params
   */
  const upsertRouterQuery = (queryOverride?: QueryParams) => {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, ...queryOverride },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  /**
   * Wrapper around router.push. Does a shallow push, and deletes router.query
   */
  const clearRouterQuery = () => {
    router.push(
      {
        pathname: router.pathname,
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  /**
   * Wrapper around router.push. Simply goes to the page specified using a shallow push
   * and passes in query params specified.
   *
   * NOTICE: This is an update not an upsert, the only query params pushed are the ones you provide
   */
  const goToPage = (pathname: string, queryOverride?: QueryParams) => {
    const queryObj = {
      pathname,
      query: { ...queryOverride },
    };
    router.push(queryObj, undefined, { shallow: true });
  };

  /**
   * Clear router.query params from router. Does nothing else.
   */
  const clearSearchState = () => {
    // Clears all state in redux related to the current search
    clearReduxSearchState();
    // Reset router
    const queryObj = {
      query: { id: router.query.id },
    };
    router.push(queryObj, undefined, { shallow: true });
  };

  /**
   * Clear all user target app state and go home; accepts optional router.query overrides.
   */
  const goToHome = (queryOverride?: QueryParams) => {
    const queryObj = {
      pathname: homepage,
      query: { ...queryOverride },
    };
    router.push(queryObj, undefined, { shallow: true });
  };

  /**
   * Clear all user target app state and go home without a shallow push; accepts optional router.query overrides.
   */
  const goToHomeReallyHard = (queryOverride?: QueryParams) => {
    // Clear all user state
    clearUserTargetState();

    const queryObj = {
      pathname: "/",
      query: { ...queryOverride },
    };
    router.push(queryObj, undefined, { shallow: false });
  };

  /**
   * Open modal for doc with docId
   */
  const goToDoc = (docId: string, repoId?: string) => {
    dispatch(setDocModalVisible({ docId, repoId }));
  };

  /**
   * Clear all user target app state and go to repo; accepts optional router.query overrides.
   */
  const goToRepo = (repoId: string, queryOverride?: QueryParams) => {
    // Nav to repo
    const queryObj = {
      pathname: `/repos/${repoId}`,
      query: { ...queryOverride },
    };
    router.push(queryObj, undefined, { shallow: true });
  };

  /**
   * Go to the Add Docs page on a repo page. If docId is specified will add docs
   * to that specific document
   */
  const goToRepoAddDocs = (repoId: string, docId?: string) => {
    // Upsert doc target
    if (docId) {
      const sectionKey = getReportFilterKey(DOCUMENT_SECTION_TITLE);
      const rowKey = getReportFilterKey(sectionKey, docId);
      const searchKey = getFilterSearchKey("document");

      // Create new report filter
      const reportFilter: ReportFilter = {
        // This key is used to identify the filter at search time
        key: searchKey,
        values: docId,
        operation: ReportFilterOperation.IS,
      };
      dispatch(addReportFilter({ sectionKey, rowKey, reportFilter }));
    }

    const urlObj = {
      pathname: `/repos/${repoId}/add`,
    };
    router.push(urlObj, undefined, {
      shallow: true,
    });
  };

  /**
   * Go to a matrix.
   * @param addDocsOnLoad if true, the page will automatically send a ws message to load the matrix's cells
   */
  const goToMatrix = (
    matrixId: string,
    tabId?: string,
    addDocsOnLoad?: boolean
  ) => {
    if (addDocsOnLoad === undefined) {
      router.push(
        {
          pathname: "/matrix",
          query: {
            matrix_id: matrixId,
            tab_id: tabId,
          },
        },
        undefined,
        { shallow: true }
      );
      return;
    }

    router.push(
      {
        pathname: "/matrix",
        query: {
          matrix_id: matrixId,
          tab_id: tabId,
          add_docs_on_load: addDocsOnLoad,
        },
      },
      undefined,
      { shallow: true }
    );
  };

  /**
   * Go to matrix home.
   */
  const goToMatrixHome = (explore_templates?: boolean) =>
    router.push(
      {
        pathname: "/matrix",
        query: { explore_templates },
      },
      undefined,
      { shallow: true }
    );

  return {
    homepage,
    upsertRouterQuery,
    clearRouterQuery,
    goToPage,
    goToHome,
    goToHomeReallyHard,
    goToDoc,
    goToRepo,
    goToRepoAddDocs,
    clearSearchState,
    goToMatrix,
    goToMatrixHome,
  };
};

/**
 * Hook is for major state changes
 */
export const useSearchStateManager = () => {
  const dispatch = useDispatch();

  /**
   * Clears everything related to the current search 'state':
   * this will cancel the search, clear search error,
   * clear relevant results (suggested, applied, disasbled, appliedDate),
   * and lastly remove router.query from the url
   */
  const clearReduxSearchState = () => {
    // Cancel search
    dispatch(cancelControllers());
    // Stop loading
    dispatch(setSearchResponseLoading(false));
    dispatch(setKeywordResponseLoading(false));
    // Clear search error
    dispatch(setSearchError(null));
    dispatch(setKeywordSearchError(null));
    // Clear all relevant results
    dispatch(clearRelevantResults());
    // Clear redux search query
    dispatch(setSearchQuery(""));
    // Clear out search response
    dispatch(setSearchResponse(null));
    // Clear keyword search response
    dispatch(setKeywordResponse(null));
    // Clear auto toggle
    dispatch(setSearchAutoToggled(false));
    // Clear no docs copy
    dispatch(setNoDocsFilters(null));
    dispatch(setSearchNoDocsVisible(false));
    // Clear out search task
    dispatch(setTasks([]));
    dispatch(setTasksToDisplay([]));
  };

  /**
   * Clear sidebar state (targets, relevant results, relevant filters)
   */
  const clearSidebarState = () => {
    dispatch(clearAllReportFilterSectionsValues());
    dispatch(setTargetAppliedFilters([]));
    dispatch(clearRelevantResults());
    dispatch(setRepoNumericFilters([]));
    dispatch(setRepoStrFilters([]));
    dispatch(setDateSelected(null));
    dispatch(clearAllSearchStates());
    dispatch(setSearchQuery(""));
  };

  /**
   * Clear entire app state related to search/targets (all targets, search state, etc):
   * this is used for navigations btwn pages where we want to wipe everything
   * (going to login, opening a repo, going home, ...)
   */
  const clearUserTargetState = () => {
    clearReduxSearchState();
    clearSidebarState();
  };

  return { clearReduxSearchState, clearUserTargetState, clearSidebarState };
};
