import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { queryClient } from "pages/_app";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import {
  updateActivitiesByRepoId,
  upsertActivities,
} from "source/redux/activityFeed";
import { SegmentEvents } from "source/SegmentEvents";
import { RepoType, UpdateRepoResponse } from "source/Types";
import api from "..";
import { QueryContextFromKeys } from "../utils";
import { bookmarksKeys } from "../bookmarks/useQueryBookmarks";
import { useDeepCompareMemo } from "use-deep-compare";

// repos query key factory
export const reposKeys = {
  all: [{ scope: "repos" }] as const,
  userRepos: () => [{ ...reposKeys.all[0], level: "user" }] as const,
  repo: (repoId: string | undefined) =>
    [{ ...reposKeys.all[0], repoId, feature: "repo" }] as const,
  recent: () => [{ ...reposKeys.all[0], feature: "recent" }] as const,
  recommended: () => [{ ...reposKeys.all[0], feature: "recommended" }] as const,
  recommendedPublic: () =>
    [{ ...reposKeys.recent()[0], feature: "recent", level: "public" }] as const,
  recommendedPitchbook: () =>
    [
      { ...reposKeys.recent()[0], feature: "recent", level: "pitchbook" },
    ] as const,
  searchSuggested: (repoIds: string[]) =>
    [{ ...reposKeys.all[0], feature: "suggested", repoIds }] as const,
  alerts: (repoIds: string[]) =>
    [{ ...reposKeys.all[0], feature: "alerts", repoIds }] as const,
  // move this to filters if it starts returning more than just repos
  tickerRepos: (tickers: string[]) =>
    [{ ...reposKeys.all[0], feature: "tickers", tickers }] as const,
  snpRepos: () => [{ ...reposKeys.all[0], feature: "snpRepos" }] as const,
  searchDisabled: (docIds: string[], repoId?: string) =>
    [{ ...reposKeys.all[0], feature: "disabled", docIds, repoId }] as const,
};

// Typed repos key factory context
// QueryFunctionContext is an object that is passed as argument to the queryFn, this is simply a way of typing it
export type ReposQueryContext = QueryContextFromKeys<typeof reposKeys>;

export const useQueryRepo = (repoId: string | undefined) =>
  useQuery({
    queryKey: reposKeys.repo(repoId),
    queryFn: repoFetcher,
    enabled: !!repoId,
    retry: 2,
  });

const repoFetcher = async ({
  queryKey: [{ repoId }],
}: ReposQueryContext["repo"]) =>
  // check id at runtime because it can be `undefined`
  typeof repoId === "undefined"
    ? Promise.resolve(undefined)
    : api.repos.getRepoById(repoId);
/** Used to grab a bunch of repos individually from the cache,
 * AND will fetch the repos if they aren't in the cache -- neat!!!
 */
export const useQueryReposByIds = (repoIds: string[]) =>
  useQueries({
    queries: repoIds.map((repoId) => ({
      queryKey: reposKeys.repo(repoId),
      queryFn: repoFetcher,
    })),
  });

export const useQuerySuggestedRepos = (repoIds: string[]) => {
  const emptyArray = useMemo(() => [], []);
  const query = useQuery({
    queryKey: reposKeys.searchSuggested(repoIds),
    queryFn: searchSuggestedFetcher,
    enabled: !!repoIds.length,
  });
  return query.data ?? emptyArray;
};

const searchSuggestedFetcher = async ({
  queryKey: [{ repoIds }],
}: ReposQueryContext["searchSuggested"]) => api.repos.getReposByIds(repoIds);

export const useQueryFilterSpecificRepos = (tickers: string[]) =>
  useQuery({
    queryKey: reposKeys.tickerRepos(tickers),
    queryFn: searchTickersFetcher,
    enabled: !!tickers.length,
  });

const searchTickersFetcher = async ({
  queryKey: [{ tickers }],
}: ReposQueryContext["tickerRepos"]) => api.repos.getReposByTickers(tickers);

export const useQuerySnpRepos = () =>
  useQuery({
    queryKey: reposKeys.snpRepos(),
    queryFn: () => api.repos.getSnpRepos(),
  });

/** Hook that returns all repos that were set by repo id in the react query cache.
 * Note: this will not return repos from other places in the cache (e.g. bookmarks, search result repos, sidebar repos, etc)
 */
export const useCachedRepos = () => {
  const cachedRepoQueries = queryClient.getQueriesData([{ feature: "repo" }]);
  const cachedRepos = useDeepCompareMemo(
    () =>
      cachedRepoQueries
        .map((query) => (query.length > 1 ? query[1] : undefined))
        .filter((repo) => repo) as RepoType[],
    [cachedRepoQueries]
  );

  return cachedRepos;
};

export type CreateRepoPayload = {
  name: string;
  is_private: boolean;
  organization_id?: string;
};

export const useCreateRepoMutation = () => {
  const dispatch = useDispatch();
  const { goToRepoAddDocs } = useGlobalNavigator();

  return useMutation({
    mutationFn: (payload: CreateRepoPayload) => api.repos.create(payload),
    onSuccess: (repo: RepoType) => {
      setRepo(repo);
      queryClient.invalidateQueries({ queryKey: reposKeys.userRepos() });
      const meta = {};
      api.activityFeed
        .addActivity(repo.created_at, "add_repo", meta, repo.id)
        .then(({ data }) => {
          dispatch(upsertActivities(data.activities));
        });
      window.analytics.track(SegmentEvents.REPO_CREATE);
      goToRepoAddDocs(repo.id);
    },
  });
};

type UpdateRepoPayload = {
  repoId: string;
  body: Partial<RepoType>;
};

export const useUpdateRepoMutation = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: (payload: UpdateRepoPayload) => {
      payload.body.id = payload.repoId;
      return api.repos.update(payload.repoId, payload.body);
    },
    onSuccess: (data: UpdateRepoResponse) => {
      queryClient.invalidateQueries({ queryKey: reposKeys.userRepos() });
      updateActivitiesByRepoId(dispatch, data.repo.id, data.repo.name);
      setRepo(data.repo);
      updateActivitiesByRepoId(dispatch, data.repo.id, data.repo.name);
    },
  });
};

//** Prime a repo into the react query cache */
export const setRepo = (repo: RepoType) => {
  queryClient.setQueryData(reposKeys.repo(repo.id), repo);
};

/** Mutation to optimistically delete a repo from the cache */
export const useDeleteRepoMutation = () =>
  useMutation({
    mutationFn: (repoId: string) => api.repos.delete(repoId || ""),
    // When mutate is called:
    onMutate: async (repoId) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to repos (so we don't fetch the newly deleted repo)
      await queryClient.cancelQueries({ queryKey: reposKeys.all });
      // Cancel all refetches related to this repoId
      await queryClient.cancelQueries({ queryKey: [{ repoId }] });

      // Snapshot the previous value
      const previousRepo = queryClient.getQueryData(reposKeys.repo(repoId));

      // Optimistically delete all things related to this repoId
      queryClient.removeQueries({ queryKey: [{ repoId }] });

      // Return a context object with the snapshotted value
      return { previousRepo };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, repoId, context) => {
      queryClient.setQueryData(reposKeys.repo(repoId), context?.previousRepo);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: reposKeys.all });
      queryClient.invalidateQueries({ queryKey: bookmarksKeys.all });
    },
  });

type RemoveMutationType = {
  repoId: string;
  userId: string;
};

export const useRemoveRepoMemberMutation = () =>
  useMutation({
    mutationFn: (payload: RemoveMutationType) =>
      api.repos.removeMember(payload.repoId, payload.userId),
    // When mutate is called:
    onMutate: async ({ repoId }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      // Cancel all refetches related to repos (so we don't fetch the newly removed repo)
      await queryClient.cancelQueries({ queryKey: reposKeys.all });
      // Cancel all refetches related to this repoId
      await queryClient.cancelQueries({ queryKey: [{ repoId }] });

      // Snapshot the previous value
      const previousRepo = queryClient.getQueryData(reposKeys.repo(repoId));

      // Optimistically delete all things related to this repoId
      queryClient.removeQueries({ queryKey: [{ repoId }] });

      // Return a context object with the snapshotted value
      return { previousRepo };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, { repoId }, context) => {
      queryClient.setQueryData(reposKeys.repo(repoId), context?.previousRepo);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: reposKeys.all });
    },
  });

export const useQueryUserRepos = () =>
  useQuery({
    queryKey: reposKeys.userRepos(),
    queryFn: userReposFetcher,
  });

export const prefetchUserRepos = async () =>
  await queryClient.prefetchQuery({
    queryKey: reposKeys.userRepos(),
    queryFn: userReposFetcher,
  });

export const userReposFetcher = async () => api.repos.getUserRepos();

export const setUserRepos = (repos: RepoType[]) =>
  queryClient.setQueryData(reposKeys.userRepos(), repos);

export const useQueryRecommendedCompany = () => {
  const companyQuery = useQuery({
    queryKey: reposKeys.recommendedPublic(),
    queryFn: () => api.companies.getRecommendedRepos(),
  });
  return companyQuery.data ?? [];
};

export const useQueryRecommendedPitchbook = () => {
  const pitchbookQuery = useQuery({
    queryKey: reposKeys.recommendedPitchbook(),
    queryFn: () => api.companies.getRecommendedPitchbookRepos(),
  });
  return pitchbookQuery.data ?? [];
};

// Used to fetch the alert repos
export const useQueryAlertRepos = (repoIds: string[]) => {
  const query = useQuery({
    queryKey: reposKeys.alerts(repoIds),
    queryFn: AlertReposFetcher,
    enabled: !!repoIds.length,
  });
  return query.data ?? [];
};

const AlertReposFetcher = async ({
  queryKey: [{ repoIds }],
}: ReposQueryContext["alerts"]) => api.repos.getReposByIds(repoIds);

export const useQuerySearchDisabled = (docIds: string[], repoId?: string) => {
  const query = useQuery({
    queryKey: reposKeys.searchDisabled(docIds, repoId),
    queryFn: () => {
      if (repoId) return api.repos.getSearchDisabled(repoId, docIds);
    },
    enabled: !!repoId,
  });
  return query.data ?? false;
};

// useMutation({
//   mutationFn: updateTodo,
//   // When mutate is called:
//   onMutate: async (newTodo) => {
//     // Cancel any outgoing refetches
//     // (so they don't overwrite our optimistic update)
//     await queryClient.cancelQueries({ queryKey: ["todos"] });

//     // Snapshot the previous value
//     const previousTodos = queryClient.getQueryData(["todos"]);

//     // Optimistically update to the new value
//     queryClient.setQueryData(["todos"], (old) => [...old, newTodo]);

//     // Return a context object with the snapshotted value
//     return { previousTodos };
//   },
//   // If the mutation fails,
//   // use the context returned from onMutate to roll back
//   onError: (err, newTodo, context) => {
//     queryClient.setQueryData(["todos"], context.previousTodos);
//   },
//   // Always refetch after error or success:
//   onSettled: () => {
//     queryClient.invalidateQueries({ queryKey: ["todos"] });
//   },
// });
