import _ from "lodash";

export const findDuplicates = (array: any[]) => {
  const counts = _.countBy(array);
  return _.uniq(_.filter(array, (item) => (counts[item] ?? 0) > 1));
};

export const createArrayFromRange = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, index) => start + index);

export const numericSort = (arr: number[]) => arr.sort((a, b) => a - b);

export const chunkArray = <T>(arr: T[], size: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
};

// De-duplicates object with same key values
export const dedupeArrayByKey = (arr: any[], key: string) =>
  arr.filter((v, index) => arr.findIndex((v2) => v[key] === v2[key]) === index);

// Returns true if everything in array 2 exists in array 1
export const isArraySubset = <T>(superset: T[], subset: T[]) =>
  _.every(subset, (item) => superset.includes(item));

// Returns true if there's any intersection between arr1 and arr2
export const hasIntersection = <T>(arr1: T[], arr2: T[]) =>
  _.some(arr1, _.ary(_.partial(_.includes, arr2), 1));

export const filterUndefined = <T>(arr: (T | undefined)[]): T[] => {
  return arr.filter((item): item is T => item !== undefined);
};

export const filterNullOrUndefined = <T>(arr: (T | undefined)[]): T[] => {
  return arr.filter((item): item is T => item != null);
};
