import {
  DocumentType,
  IDocumentMime,
  IDocumentType,
  ResultType,
} from "../Types";

export const getHumanMime = (mime: IDocumentMime) => {
  switch (mime) {
    case "text/csv":
      return "CSV";
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "Word (.docx)";
    case "hebbia/earnings":
      return "Earnings Transcript";
    case "hebbia/finnhub_filing":
      return "Filing";
    case "application/vnd.google-apps.folder":
      return "Google Drive Folder";
    case "application/vnd.google-apps.document":
      return "Google Drive Document";
    case "application/vnd.google-apps.presentation":
      return "Google Drive Presentation";
    case "text/html":
      return "Web Page";
    case "hebbia/local.folder":
      return "Folder";
    case "text/markdown":
      return "Markdown";
    case "hebbia/onedrive":
    case "hebbia/onedrive.folder":
    case "hebbia/onedrive.shared":
      return "Microsoft OneDrive";
    case "hebbia/onenote.book":
    case "hebbia/onenote.section":
    case "hebbia/onenote.page":
      return "Microsoft OneNote";
    case "hebbia/sharepoint.list":
    case "hebbia/sharepoint.site":
      return "Microsoft SharePoint";
    case "application/pdf":
      return "PDF (.pdf)";
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "Powerpoint (.pptx)";
    case "hebbia/sec":
      return "SEC Filing";
    case "text/plain":
      return "Text Document";
    case "application/vnd.ms-excel":
    case "application/vnd.ms-excel.sheet.macroEnabled.12":
    case "application/vnd.ms-excel.sheet.binary.macroEnabled.12":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "Excel (.xlsx)";
    case "image/jpeg":
    case "image/png":
      return "Image";
    case "hebbia/folder":
      return "Folder";
  }
};

// Many SEC filings / earnings reports have mime type "text/html"
// Distinguish these docs from a standard "Web Page" on table view
export const getDocViewerMime = (type: IDocumentType, mime: IDocumentMime) => {
  if (mime === "text/html" && type === "sec") return "SEC Filing";
  if (mime === "text/html" && type === "earnings") return "Earnings Call";
  else return getHumanMime(mime);
};

export const isShortDoc = (doc: DocumentType) =>
  doc.type == "web" &&
  doc.source?.includes("reddit") &&
  doc.npassages &&
  doc.npassages < 10;

export const isFailedDoc = (doc: DocumentType) => doc.failure_reason;

export const isPasswordProtectedDoc = (doc: DocumentType) =>
  doc.failure_reason && doc.failure_reason === "Document is password protected";

export const isExcelDoc = (doc: DocumentType | ResultType) =>
  doc.mime === "application/vnd.ms-excel" ||
  doc.mime === "application/vnd.ms-excel.sheet.binary.macroEnabled.12" ||
  doc.mime ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const isLocalExcelDoc = (doc: DocumentType | ResultType) =>
  doc.type === "local" && isExcelDoc(doc);

// Returns true if a doc can hold other docs
export const isFolder = (docMime?: IDocumentMime) => {
  switch (docMime) {
    case "application/vnd.google-apps.folder":
    case "hebbia/local.folder":
    case "hebbia/onedrive.folder":
    case "hebbia/folder":
    case "hebbia/dropbox.folder":
    case "hebbia/s3.folder":
    case "hebbia/findox.folder":
    case "hebbia/findox.holding":
    case "hebbia/box.folder":
    case "hebbia/tegus.network":
    case "hebbia/sharepoint.site":
    case "hebbia/sec":
    case "hebbia/earnings":
      return true;
    case "hebbia/finnhub_filing":
      return true;
    default:
      return false;
  }
};

export const isDocIndexing = (doc?: DocumentType): boolean => {
  // If doc is not available, it's not indexing
  if (!doc) return false;

  // If doc has a failure reason, it's not indexing
  if (doc.failure_reason) return false;

  // If doc has been built, it's not indexing
  if (doc.last_built || doc.fast_build_status === "success") {
    return false;
  }

  // If doc is in progress or not run, it's indexing
  if (
    doc?.fast_build_status === "in_progress" ||
    doc?.fast_build_status === "not_run"
  ) {
    return true;
  }

  // If doc has failed, it's not indexing
  if (doc?.fast_build_status === "failed") {
    return false;
  }

  // Otherwise assume it's indexing
  return true;
};

export const isDocSearchable = (doc: DocumentType): boolean => {
  return !!doc.last_built && !doc.failure_reason;
};
