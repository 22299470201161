import React from "react";
import { ManipulateType } from "dayjs";
import { MaterialSymbolsIcon } from "core";

export type PresetFilterSelection =
  | "month"
  | "3 months"
  | "year"
  | "2 years"
  | "5 years"
  | "10 years"
  | "20 years"
  | "all time"
  | "customRange";

export type CustomRange = {
  start: string | null;
  end: string | null;
};

export const PRESET_UNIT_MAPPING: Record<
  string,
  {
    count: number;
    unit: ManipulateType;
  }
> = {
  month: { count: 1, unit: "month" },
  "3 months": { count: 3, unit: "month" },
  year: { count: 1, unit: "year" },
  "2 years": { count: 2, unit: "year" },
  "5 years": { count: 5, unit: "year" },
  "10 years": { count: 10, unit: "year" },
  "20 years": { count: 20, unit: "year" },
};

export const getCalendarIcon = (preset: PresetFilterSelection) => {
  switch (preset) {
    case "month":
      return (
        <MaterialSymbolsIcon
          filled
          icon="calendar_today"
          variant="rounded"
          size="base"
          className="text-gray-500"
        />
      );
    case "3 months":
      return (
        <MaterialSymbolsIcon
          filled
          icon="date_range"
          variant="rounded"
          size="base"
          className="text-gray-500"
        />
      );
    case "year":
    case "5 years":
    case "10 years":
    case "20 years":
    case "all time":
      return (
        <MaterialSymbolsIcon
          filled
          icon="calendar_month"
          variant="rounded"
          size="base"
          className="text-gray-500"
        />
      );
    case "customRange":
      return (
        <MaterialSymbolsIcon
          filled
          icon="edit_calendar"
          variant="rounded"
          size="base"
          className="text-gray-500"
        />
      );
    default:
      return (
        <MaterialSymbolsIcon
          filled
          icon="calendar_today"
          variant="rounded"
          size="base"
          className="text-gray-500"
        />
      );
  }
};

export const DATE_PRESETS: PresetFilterSelection[] = [
  "month",
  "3 months",
  "year",
];
