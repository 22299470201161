import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from ".";
import { MatrixChatMessageType } from "source/types/matrix/matrixChat.types";
import {
  convertRangeCitationsToIndividualCitations,
  getCitationNumbers,
} from "source/utils/matrix/citations";
import { getDefaultAgentMessage } from "source/components/matrix/matrix-chat/shared/helpers";
import { PUBLICS_DOCUMENT_LIST_ID } from "source/constants/documentList";
import { FilterModel } from "ag-grid-community";
import { ToolOutputType } from "source/components/matrix/types/tools.types";

// Track col ID / name. Defined a new type as ColumnMetadata includes x
export type MatrixColConversionRequest = {
  staticColumnId: string;
  name: string;
  newType: ToolOutputType;
};

export type MatrixChatReduxType = {
  isLoading: boolean;
  messages: MatrixChatMessageType[];
  isHistoryLoaded: boolean;
  chatError: boolean;
  tokenLimitError: boolean;
  search: string | null; // Stores search that is displayed
  autoRun: boolean;
  citationIdMap: { [key: string]: string }; // Stores a mapping between the index and the cell id
  activeCitations: { [key: string]: string }; // Stores a mapping between the cellId and the index referenced by the agent
  isReadOnlyMode: boolean;
  documentListId: string;
  mostRecentMatrixChatActions: {
    columns_added: string[] | null;
    documents_added: string[] | null;
    grid_filter: FilterModel | null; // AG Grid filter
  };
  columnTypeConversionRequests: MatrixColConversionRequest[] | null; // track column type conversion requests from operation tool
  didUserGenerateChart: boolean;
};

const initialState: MatrixChatReduxType = {
  isLoading: false,
  messages: [],
  isHistoryLoaded: false,
  chatError: false,
  tokenLimitError: false,
  search: "",
  autoRun: false,
  citationIdMap: {},
  activeCitations: {},
  isReadOnlyMode: false,
  documentListId: PUBLICS_DOCUMENT_LIST_ID,
  mostRecentMatrixChatActions: {
    columns_added: null,
    documents_added: null,
    grid_filter: null,
  },
  columnTypeConversionRequests: null,
  didUserGenerateChart: false,
};

export const getMatrixChatMessages = (state: ReduxState) =>
  state.matrixChat.messages;

export const getIsMatrixChatLoading = (state: ReduxState) =>
  state.matrixChat.isLoading;

export const getChatError = (state: ReduxState) => state.matrixChat.chatError;

export const getTokenLimitError = (state: ReduxState) =>
  state.matrixChat.tokenLimitError;

export const getMatrixChatSearch = (state: ReduxState) =>
  state.matrixChat.search;

export const getAutoRunMatrixChat = (state: ReduxState) =>
  state.matrixChat.autoRun;

export const getCitationIdMap = (state: ReduxState) =>
  state.matrixChat.citationIdMap;

export const getActiveCitations = (state: ReduxState) =>
  state.matrixChat.activeCitations;

export const getIsHistoryLoaded = (state: ReduxState) =>
  state.matrixChat.isHistoryLoaded;

export const getIsMatrixChatReadOnlyMode = (state: ReduxState) =>
  state.matrixChat.isReadOnlyMode;

export const getMatrixChatDocumentListId = (state: ReduxState) =>
  state.matrixChat.documentListId;

export const getMostRecentMatrixChatActions = (state: ReduxState) =>
  state.matrixChat.mostRecentMatrixChatActions;

export const getColTypeConversionRequests = (state: ReduxState) =>
  state.matrixChat.columnTypeConversionRequests;

export const getDidUserGenerateChart = (state: ReduxState) =>
  state.matrixChat.didUserGenerateChart;

const matrixChatSlice = createSlice({
  name: "matrixChat",
  initialState,
  reducers: {
    setMatrixChatMessages: (
      state,
      action: PayloadAction<MatrixChatMessageType[]>
    ) => {
      state.messages = action.payload;
      return state;
    },
    addMatrixChatMessages: (
      state,
      action: PayloadAction<MatrixChatMessageType[]>
    ) => {
      state.messages.push(...action.payload);
      return state;
    },
    upsertMatrixChatMessage: (
      state,
      action: PayloadAction<MatrixChatMessageType>
    ) => {
      const messageIdx = state.messages.findIndex(
        (message) => message.id === action.payload.id
      );

      if (messageIdx !== -1) {
        if (state.messages?.[messageIdx]?.stopped) {
          action.payload.loading = false;
        }
        // Message already exists, so upsert it
        state.messages[messageIdx] = {
          ...state.messages[messageIdx],
          ...action.payload,
        };
      }
      // New message, so add it
      else {
        state.messages.push(action.payload);
      }
      return state;
    },
    setIsHistoryLoaded: (state, action: PayloadAction<boolean>) => {
      state.isHistoryLoaded = action.payload;
      return state;
    },
    setIsMatrixChatLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setChatError: (state, action: PayloadAction<boolean>) => {
      state.chatError = action.payload;
      return state;
    },
    setTokenLimitError: (state, action: PayloadAction<boolean>) => {
      state.tokenLimitError = action.payload;
      return state;
    },
    setMatrixChatSearch: (state, action: PayloadAction<string | null>) => {
      state.search = action.payload;
      return state;
    },
    setMatrixChatAutoRun: (state, action: PayloadAction<boolean>) => {
      state.autoRun = action.payload;
      return state;
    },
    setCitationIdMap: (
      state,
      action: PayloadAction<{ [key: string]: string }>
    ) => {
      state.citationIdMap = action.payload;
      return state;
    },
    setActiveCitations: (
      state,
      action: PayloadAction<{
        [key: string]: string;
      }>
    ) => {
      state.activeCitations = action.payload;
      return state;
    },
    addCitationsFromMessage: (state, action: PayloadAction<string>) => {
      const payload = convertRangeCitationsToIndividualCitations(
        action.payload
      );
      const citationsToAdd = getCitationNumbers(payload);

      // If the citation is valid in the CitationInfo dictionary, add it to the current citations
      for (const citation of citationsToAdd) {
        const cellId = state.citationIdMap[citation];
        if (cellId) {
          state.activeCitations[cellId] = citation;
        }
      }
      return state;
    },
    setMatrixChatMessageLoading: (state, action: PayloadAction<boolean>) => {
      const lastMessageIndex = state.messages.length - 1;
      if (state.messages[lastMessageIndex]) {
        if (state.messages[lastMessageIndex]?.content.length === 0) {
          state.messages.pop();
        } else {
          state.messages[lastMessageIndex] = {
            ...(state.messages[lastMessageIndex] as MatrixChatMessageType),
            loading: action.payload,
          };
        }
      }
    },
    clearMatrixChatState: (
      state,
      action: PayloadAction<{
        name?: string;
      }>
    ) => {
      const { name } = action.payload;

      state.isLoading = false;
      state.chatError = false;
      state.tokenLimitError = false;
      state.search = "";
      state.citationIdMap = {};
      state.activeCitations = {};

      if (state.isHistoryLoaded) {
        state.messages = [
          getDefaultAgentMessage({
            name,
          }),
        ];
      } else {
        state.messages = [];
      }
      return state;
    },
    resetMatrixChatState: (state) => {
      state = initialState;
      return state;
    },
    setIsMatrixChatReadOnlyMode: (state, action: PayloadAction<boolean>) => {
      state.isReadOnlyMode = action.payload;
      return state;
    },
    setMatrixChatDocumentListId: (state, action: PayloadAction<string>) => {
      state.documentListId = action.payload;
      return state;
    },
    setMostRecentMatrixChatActions: (
      state,
      action: PayloadAction<{
        columns_added?: string[];
        documents_added?: string[];
      }>
    ) => {
      state.mostRecentMatrixChatActions.columns_added =
        action.payload.columns_added ?? null;
      state.mostRecentMatrixChatActions.documents_added =
        action.payload.documents_added ?? null;
      return state;
    },
    clearMostRecentMatrixChatActions: (state) => {
      state.mostRecentMatrixChatActions.columns_added = null;
      state.mostRecentMatrixChatActions.documents_added = null;
      return state;
    },
    setMatrixChatGridFilter: (
      state,
      action: PayloadAction<FilterModel | null>
    ) => {
      state.mostRecentMatrixChatActions.grid_filter = action.payload;
      return state;
    },
    setColumnTypeConversionRequests: (
      state,
      action: PayloadAction<MatrixColConversionRequest[] | null>
    ) => {
      state.columnTypeConversionRequests = action.payload;
      return state;
    },
    removeColumnTypeConversionRequestById: (
      state,
      action: PayloadAction<string>
    ) => {
      state.columnTypeConversionRequests =
        state.columnTypeConversionRequests?.filter(
          (col) => col.staticColumnId != action.payload
        ) ?? null;
      return state;
    },
    setDidUserGenerateChart: (state, action: PayloadAction<boolean>) => {
      state.didUserGenerateChart = action.payload;
      return state;
    },
  },
});

export const {
  setMatrixChatMessages,
  addMatrixChatMessages,
  upsertMatrixChatMessage,
  setIsHistoryLoaded,
  setIsMatrixChatLoading,
  setChatError,
  setTokenLimitError,
  setMatrixChatSearch,
  setMatrixChatAutoRun,
  setCitationIdMap,
  setActiveCitations,
  addCitationsFromMessage,
  setMatrixChatMessageLoading,
  clearMatrixChatState,
  resetMatrixChatState,
  setIsMatrixChatReadOnlyMode,
  setMatrixChatDocumentListId,
  setMostRecentMatrixChatActions,
  clearMostRecentMatrixChatActions,
  setMatrixChatGridFilter,
  setColumnTypeConversionRequests,
  removeColumnTypeConversionRequestById,
  setDidUserGenerateChart,
} = matrixChatSlice.actions;
export const matrixChatReducer = matrixChatSlice.reducer;
