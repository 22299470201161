export const SegmentEvents = {
  // search
  SEARCH: "Search",
  // topbar
  TOPBAR_TOGGLE_SHOW_DISCLAIMER: "Toggle show repo name",
  // answer card operations
  ANSWER_CARD_ACTIVATE: "Answer card activate",
  ANSWER_CARD_EXPAND: "Answer card expand",
  ANSWER_CARD_OPEN_SOURCE: "Answer card open source",
  ANSWER_PIN: "Pin answer",
  ANSWER_UNPIN: "Unpin answer",
  // repo
  REPO_CREATE: "Create repo",
  // report
  REPORT_CREATE: "Create report",
  REPORT_RUN: "Run report",
  REPORT_NAVIGATE: "Navigate report questions",
  REPORT_PAUSE: "Pause report",
  REPORT_NEXT: "Next question in report",
  REPORT_PREV: "Prev question in report",
  REPORT_EXPORT: "Export report",
  REPORT_ADD_QUESTION: "Add question to report",
  REPORT_UPDATE_QUESTION: "Update question from report",
  REPORT_DELETE_QUESTION: "Delete question from report",
  REPORT_BATCH_EDIT: "Report batch edit",
};
