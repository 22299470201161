import React, { forwardRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "core";

export interface ModalProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  overlayClassName?: string;
}

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (props: ModalProps, ref) => {
    const { children, isOpen, onClose, overlayClassName, className, ...rest } =
      props;

    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          open={isOpen}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                "fixed inset-0 bg-black bg-opacity-10 transition-opacity",
                overlayClassName
              )}
            />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex h-screen items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  {...rest}
                  ref={ref}
                  as="div"
                  className={classNames(
                    "relative flex max-h-[80vh] max-w-[calc(100%-32px)] transform flex-col overflow-hidden rounded-lg border border-gray-200 bg-white shadow-xl transition-all",
                    className
                  )}
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  }
);

export default Modal;
