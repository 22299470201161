import { ReportTableRow } from "source/components/matrix/types/cells.types";
import {
  BulkRunStatusResponse,
  MatrixUser,
  Report,
  ToolSpecType,
} from "source/components/matrix/types/reports.types";
import { ToolOutputType } from "source/components/matrix/types/tools.types";
import { MatrixRoleType } from "source/Types";

export type RemoveMatrixUserParams = {
  matrixId: string;
  userId: string;
};

export type AddMatrixUserParams = {
  matrixId: string;
  email: string;
  role: MatrixRoleType;
};

export type EditMatrixUserParams = {
  matrixId: string;
  userId: string;
  role: MatrixRoleType;
};

export type MatrixUsersResponse = {
  [key: string]: MatrixUser;
};

export enum MatrixPermissionLevel {
  PRIVATE = "private",
  PUBLIC = "public",
  ORG = "org",
}

export type MatrixShareSettings = {
  id: string;
  matrixId: string;
  shareSettings: MatrixPermissionLevel;
};

export type MatrixRoleResponse = {
  sheet_id: string;
  role: MatrixRoleType;
};

export type MatrixMetadataResponse = {
  sheet_id: string;
  sheet_org_id: string;
  role: MatrixRoleType;
  is_exclusively_org_viewer?: boolean; // true if a user ONLY has org-level access
  build_status: BulkRunStatusResponse;
  name: string;
  sheet_session_id: string | null;
  bookmarked?: boolean;
};

export type UpdateShareSettingsParams = {
  matrixId: string;
  permissionLevel: MatrixPermissionLevel;
  createIfMissing: boolean;
};

export type UpdateMatrixBookmakredParams = {
  reportId: string;
  bookmarked: boolean;
};

export enum RowCellState {
  DOCS,
  ANSWERED,
  EMPTY,
  DOCUMENT_FAILED,
}

export type RowStateMapType = {
  [key: string]: RowCellState;
};

export type MatrixLeaseResponse = {
  lease_id: string;
  user_id: string;
  user_email: string;
  user_name: string;
  number_of_editors: number;
  profile_picture: string;
};

export type GeneratePromptRequest = {
  userInput: string;
  sheetId: string;
  outputType?: string;
  outputFormat?: string;
};

export type GeneratePromptResponse = {
  prompt: string;
  output_type: ToolOutputType;
  tool_spec: ToolSpecType;
  scale?: string; // this field will be set on the view-config
};

export type ReportAndCellsResponse = {
  sheet_meta: Report;
  cells: ReportTableRow[];
};

export type SuggestColumnsResponse = {
  columns: ColumnType[];
};

export type ColumnType = {
  column_name: string;
  field_type: string;
  field_reasoning: string;
  description: string;
};

export type SuggestChartsResponse = {
  tako_cards: {
    url: string;
    iframe: string;
    chart_description: string;
    title: string;
    source_url: string;
    specificity: string;
    pub_id: string;
  }[];
};

export type TemplateMetadataParams = {
  template_id: string;
  template_metadata: {
    pinned: boolean;
  };
};
