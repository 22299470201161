import React from "react";
import List, { ListProps } from "./List";
import { useMenu } from "./MenuContext";
import { useComponentId } from "../../hooks/useComponentId";

export type MenuProps<T extends React.ElementType> = ListProps<T>;

const Menu = React.forwardRef(
  <T extends React.ElementType>(
    props: MenuProps<T>,
    ref: React.Ref<HTMLElement>
  ) => {
    const { getMenuProps } = useMenu();
    const internalRef = React.useRef<HTMLElement>(null);
    const { id, ...rest } = props;

    const menuId = useComponentId(id, "Menu");

    const { ref: menuRef, ...contextProps } = getMenuProps();

    // this hook allows us to forward the ref to both any ref passed directly to
    // the <Menu> component as well as any ref passed down through getMenuProps()
    React.useImperativeHandle(menuRef, () => {
      // this error will never get called since we always pass ref through to the DOM, if you're seeing it, you broke something
      if (internalRef.current === null) {
        throw new Error("internalRef.current is null");
      }
      return internalRef.current;
    });
    React.useImperativeHandle(ref, () => {
      // this error will never get called since we always pass ref through to the DOM, if you're seeing it, you broke something
      if (internalRef.current === null) {
        throw new Error("internalRef.current is null");
      }
      return internalRef.current;
    });

    return <List {...rest} ref={internalRef} id={menuId} {...contextProps} />;
  }
);

export default Menu;
