import { EmojiData } from "emoji-mart";
import { SearchResponseType, SummaryResponseType } from "./redux/search";
import { FastBuildSource } from "./types/matrix/fastBuild.types";

export interface StyleSheet {
  [key: string]: React.CSSProperties;
}

export type RepoRoleType = "viewer" | "editor" | "owner" | null;
export type OrgRoleType = "member" | "admin";
export type MatrixRoleType = "viewer" | "editor" | "owner";

export type DocumentEditFieldType = "title" | "timestamp";

export type FontSizeMui = "small" | "medium" | "large" | "inherit";

export type IDocumentType =
  | "hebbia_folder"
  | "earnings"
  | "gdrive"
  | "findox"
  | "local"
  | "onedrive"
  | "onenote"
  | "sec"
  | "sharepoint"
  | "web"
  | "rssfeed"
  | "box"
  | "affinity";

export type IDocumentMime =
  | "text/csv"
  | "application/msword"
  | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  | "hebbia/dropbox.folder"
  | "hebbia/earnings"
  | "hebbia/finnhub_filing"
  | "application/vnd.google-apps.folder"
  | "application/vnd.google-apps.document"
  | "application/vnd.google-apps.presentation"
  | "text/html"
  | "hebbia/local.folder"
  | "text/markdown"
  | "hebbia/findox.folder"
  | "hebbia/findox.holding"
  | "hebbia/folder"
  | "hebbia/onedrive"
  | "hebbia/onedrive.folder"
  | "hebbia/onedrive.shared"
  | "hebbia/onenote.book"
  | "hebbia/onenote.section"
  | "hebbia/onenote.page"
  | "hebbia/sharepoint.list"
  | "hebbia/sharepoint.site"
  | "hebbia/tegus.network"
  | "application/pdf"
  | "application/vnd.ms-powerpoint"
  | "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  | "hebbia/sec"
  | "text/plain"
  | "application/vnd.ms-excel"
  | "application/vnd.ms-excel.sheet.macroEnabled.12"
  | "application/vnd.ms-excel.sheet.binary.macroEnabled.12"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  | "application/rss+xml"
  | "application/xml"
  | "text/xml"
  | "image/jpeg"
  | "image/png"
  | "hebbia/box.folder"
  | "hebbia/s3.folder"

  // for .msg files
  // https://www.loc.gov/preservation/digital/formats/fdd/fdd000379.shtml
  | "application/vnd.ms-outlook";

export interface ResultType {
  id: string;
  title: string;
  display_title: string;
  source: string;
  text: string;
  context: string; // hebbia HTML passages
  context_char_start: number;
  context_char_end: number;
  relevance: number;
  type: IDocumentType;
  mime: IDocumentMime;
  build_id: string;
  doc_id: string;
  passage_id: string;
  repo_id: string;
  hash: string; // hash of document
  timestamp: string;
  data?: any;
  origin?: string;
  best_highlight: string;
  best_highlight_idx: number;
  highlights: any[];
  doc_viewer?: any[];
  doc_viewer_url?: string;
  keyword_counts?: Record<string, number>;
  collatedResults?: ResultType[];
  isChild?: boolean;
  meta?: DocumentMetaType;
  is_table?: boolean;
  scores?: any;
  score_weights?: any;
  absolute_relevance: number;
  smoothed_absolute_relevance: number;
  query_profiles?: string[];
  npassages?: number;
  page?: number;
  first_passage_context?: string;
  first_passage_text?: string;
  document_anlaysis_performed?: boolean;

  // Optionally contains a GTD summary of the result's best highlight. Generally
  // used in the doc viewer modal
  summary?: string;

  // Hydrated outside of search from toolformer
  task_id?: string;
}

export type SynthesisContentsType = {
  synthesisText: string;
  url: string;
};

export type OrgType = {
  id: string;
  auth0_id: string;
  name: string;
  email_domains: string[];
  logo_url: string;
  auto_join: boolean;
  requires_mfa: boolean;
  members?: UserType[];
  pending_members?: PendingUserType[];
  config: Record<string, any>;
  integrations: Record<string, any>;
};

export type UserRepository = {
  user_id: string;
  role: RepoRoleType;
  name: string;
  email: string;
};

export type PinType = {
  created_at: string;
  updated_at: string;
  id: string;
  query: string;
  result: ResultType;
  repo_id: string;
  doc_id?: string;
  created_by: string;
  highlight_context?: HighlightContextType;
  is_link?: boolean;
};

export type GPTAnswerPinType = {
  created_at: string;
  updated_at: string;
  id: string;
  org_id: string;
  doc_id?: string;
  repo_id: string;
  query?: string;
  answer?: string;
  results_ids?: string[]; // not a typo unfortunately - matches backend
  created_by: string;
};

export type ReportType = {
  id: string;
  title: string;
  user_id: string;
  org_id?: string;
  repo_id?: string;
  doc_id?: string;
  doc_ids?: string[]; // For Grid and normal flow
  template: string;
  is_template: boolean;
  is_grid: boolean;
  type_conditioned: boolean;
  created_at: string;
  updated_at: string;
  is_global: boolean;
};

export type ReportQuestionType = {
  id: string;
  question: string;
  parent_id?: string;
  report_id: string;
  order: string;
  // Generated by frontend
  children?: ReportQuestionType[];
};

export type ReportAnswerStatusType =
  | "queued"
  | "failed"
  | "pending"
  | "approved"
  | "rejected"
  | "no_results";

export type BuildActionType =
  | "add"
  | "build"
  | "repo_build"
  | "upload"
  | "ocr"
  | "tables";

export type BuildStatusType = "processing" | "succeeded" | "failed";

export type ReportAnswerType = {
  id: string;
  report_id: string;
  question_id: string;
  answer?: ResultType;
  text: string;
  syn_text: string;
  syn_score: number;
  modified: boolean;
  status: ReportAnswerStatusType;
  doc_id?: string;
  // Sometimes generated by frontend
  question?: ReportQuestionType;
  created_at: string;
  updated_at: string;
};

export type RepoType = {
  last_built: string | null;
  color: string;
  description: string;
  icon: string;
  emoji: EmojiData;
  name: string;
  id: string;
  questions?: string[];
  tags?: string[];
  role: RepoRoleType | null;
  private: boolean;
  organization_id: string | null;
  has_docs: boolean;
  is_claimed: boolean;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  // Updated by frontend status checks
  status_percentage?: number;
  ticker?: string;
  cron_interval?: number;
  integrations?: Record<string, any>;
  textract_enabled?: boolean;
  active_build_id?: string;
  num_docs?: number; // approximate
};

export type DownloadBuildType = {
  id: string;
  repo_id: string;
  doc_ids?: string[];
  build_status: DownloadBuildStatusType;
  status_percentage: number;
  url?: string;
};

export type UserDownloadType = {
  id: string;
  status: UserDownloadStatusType;
  url?: string;
};

export type UserPlatformRole = "user" | "admin" | "labeler";

export type UserType = {
  picture: string;
  name: string;
  id: string;
  auth0_id: string;
  email: string;
  created_at: string;
  google_token: string;
  platform_role: UserPlatformRole;
  is_locked: boolean;
  is_email_verified: boolean;
  repo_role?: RepoRoleType;
  org_role?: OrgRoleType;
};

export type PendingUserType = {
  email: string;
  org_role: OrgRoleType;
};

export type SECEntityType = {
  name: string;
  ticker: string;
  exchange: string;
};

export type DownloadBuildStatusType =
  | "SUCCEEDED"
  | "BUILDING"
  | "QUEUED"
  | "FAILED";

export type UserDownloadStatusType =
  | "SUCCEEDED"
  | "BUILDING"
  | "QUEUED"
  | "FAILED";

export type DocumentBuildStatusType =
  | "queued"
  | "fetching"
  | "parsing"
  | "encoding_and_feeding"
  | "success"
  | "failed";

export type DocumentFastBuildStatusType =
  | "not_run"
  | "in_progress"
  | "running_ocr"
  | "success"
  | "failed";

export type DocumentFailureReasonType =
  | "Hebbia could not locate your document"
  | "Hebbia could not retrieve your document"
  | "Hebbia could not parse your document"
  | "Hebbia could not index your document"
  | "Unsupported document type"
  | "Document does not exist"
  | "Document is too large"
  | "No parseable text found"
  | "Failed to read Excel document"
  | "Failed to convert document to PDF"
  | "Document is password protected"
  | "Document build was cancelled"
  | "Document needs OCR"
  | "OCR failed"
  | "No text extracted from OCR";

export type DocumentMetaType = {
  [key: string]: string;
};

export type ActionType =
  | "add_docs"
  | "generate_report"
  | "create_pin"
  | "create_gpt_answer_pin"
  | "create_doc_pin"
  | "upcoming_earnings_transcript"
  | "new_earnings_transcript"
  | "new_sec_filing"
  | "new_findox_doc"
  | "add_repo"
  | "new_findox_doc"
  | "import_findox_repo";

export type TableType = {
  url: string;
  img_url?: string;
  doc_viewer?: any[];
  total_bbox?: number[];
  page_number: number;
  table_name: string;
  new_table?: boolean;
  id: string;
  updated_at?: string;
  confidence?: number;
};

export type TableExctractionJobStatus =
  | "queued"
  | "in_progress"
  | "success"
  | "failed";

export type TableExtractionJobsLog = {
  id: string;
  user_id: string;
  doc_id: string;
  build_id: string;
  status: TableExctractionJobStatus;
  completed_at: string;
  found_tables: boolean;
  extracted_text: string;
  overlapping_table_id?: string;
};

export type DocumentBytesType = {
  id: string;
  bytes: Uint8Array;
};

export type DocumentType = {
  id: string;
  repo_id: string;
  title: string;
  display_title?: string;
  type: IDocumentType;
  mime: IDocumentMime;
  timestamp: string;
  data: Record<string, any>;
  updated_at: string;
  created_at: string;
  num_children: number;
  meta?: Record<string, any>;
  source?: string;
  parent_id?: string;
  failure_reason?: DocumentFailureReasonType;
  tables?: string[];
  tables_data?: TableType[];
  has_tables?: boolean;
  // Used to keep track of table update status for table extraction
  tables_updated_at?: string;
  topics?: string[];
  questions?: string[];
  build_status?: DocumentBuildStatusType;
  fast_build_status?: DocumentFastBuildStatusType;
  status_percentage?: number;
  last_built?: string | null;
  npassages?: number;
  file_size?: number;
  doc_viewer_url?: string;
  md_doc_viewer_url?: string;
  doc_raw_content_json?: string;
  path?: string[];
  uploaded_by?: string;
  build_id?: string;
  // Added by back end to display when a user last viewed a doc
  last_viewed?: string;
};

// DocumentV2 display data
export type UserDefinedDisplayData = {
  source_document_url?: string;
};

export type SimpleDocumentType<TData = Record<string, any>> = {
  id: string;
  repo_id: string;
  title: string;
  display_title?: string;
  type: IDocumentType;
  mime: IDocumentMime;
  timestamp: string;
  data: TData;
  updated_at: string;
  created_at: string;
  num_children: number;
  parent_id?: string;
  failure_reason?: DocumentFailureReasonType;
  build_status?: DocumentBuildStatusType;
  npassages?: number;
  path?: string[];
  user_defined_display_data?: UserDefinedDisplayData; // For V2 (Fast Build) docs
  integration?: FastBuildSource; // For V2 (Fast Build) docs
};

export type SimpleDocumentDriveDataType = {
  drive_id?: string;
  remote_id?: string;
};

export type EarningsDocumentDataType = {
  sp_event_id?: number;
  fiscal_year?: number;
  fiscal_quarter?: number;
  ticker?: string;
};

export type AzureDocumentDataType = {
  id: string;
};

export type ActivityType = {
  id: string;
  user_id: string;
  user_name: string;
  repo_id: string;
  repo_name: string;
  org_id: string;
  org_name: string;
  created_at: string;
  updated_at: string;
  meta: Meta;
  action_type: ActionType;
};

export type KeyValuePair = {
  key: string;
  value: string;
  operation?: string;
};

export type FilterType = {
  key: string;
  value: string;
  highlight_value?: string;
  repo?: RepoType;
};

export type NumericFilterType = {
  key: string;
  min_value: number;
  max_value: number;
};

export type SuggestedFilterType = {
  key: string;
  value: string;
  highlight_value?: string;
};

export type FilterGroupType = {
  id: string;
  name: string;
  filters: FilterType[];
};

export type Meta = {
  number_of_docs?: number;
  doc_id?: string;
  doc_type?: IDocumentType;
  doc_mime?: IDocumentMime;
  report_id?: string;
  title?: string;
  query?: string;
  // Used if doc is fed minutes
  fed_minutes?: boolean;
  // Used to reference pins
  pin_id?: string;
  // If the pin was created by code
  auto_created?: boolean;
  passage_id?: string;
  // Used to reference highlights in docs
  selection_id?: string;
  selection_text?: string;
};

export type SearchHistoryType = {
  id: string;
  query: string;
  repo_ids?: string[];
  created_at: string;
  updated_at: string;
};

export type HedgeFundType = {
  id: string;
  name: string;
};

export type SuggestionType = {
  type: string;
  start?: string;
  end?: string;
  funds?: any[];
};

export type QuestionViewModeType = "view" | "edit";

export type SortKey = "time" | "relevance";

export type SearchParams = {
  query?: string;

  // targets
  repo_ids: string[];
  protected_user_selected_repos?: string[] | null;
  doc_ids?: string[];
  // filters?: (FilterType | NumericFilterType)[];
  filters?: (FilterType | NumericFilterType)[] | string;
  disabled_filters?: FilterType[] | null;

  start_date?: string;
  end_date?: string;
  title?: string;
  doc_class?: string;
  class_threshold?: number;

  // settings
  group_by?: GroupByType;
  // Currently only used on the backend for sec/earnings filtering in all ticker repos search
  company_docs_filter?: CompanyDocsFilterType;
  sort_by?: SortKey;
  should_rerank?: boolean;
  hit_staging_reranker?: boolean;
  use_titles?: boolean;
  raw_colbert_results?: boolean;
  page?: number;
  ignore_ids?: string[];
  disable_spell_check?: boolean;
  disable_company_detection?: boolean;
  disable_date_suggestion?: boolean;
  is_single_doc_search?: boolean;
  is_global_search?: boolean;

  keywords_only?: boolean;
  include_keyword_search?: boolean;
  use_rephrase?: boolean;
  use_toolformer?: boolean; // Use multihop search flow, TODO: rename to multihop
  use_hyde?: boolean;
  hyde_prefix?: string | null | undefined;
};

export type SearchHistoryParams = {
  // search meta
  query: string;
  page: number;

  // targets
  repo_ids?: string[];
  doc_ids?: string[];
};

export type MetadataFilterType = {
  [key: string]: string[];
};

export type ActivityTimeMap = {
  [key: string]: ActivityType[];
};

export type ActivityDateMap = {
  [key: string]: ActivityTimeMap;
};

export type BookmarkType = {
  id: string;
  updated_at: string;
  created_at: string;
  repo_id: string;
  user_id: string;
};

export type GroupedPins = {
  [query: string]: PinType[];
};

export type GroupedGPTAnswerPins = {
  [query: string]: GPTAnswerPinType[];
};

export type PermissionGroup = {
  id: string;
  name: string;
  all_members: boolean;
  all_repos: boolean;
  role: RepoRoleType;
  // repo_role?: RepoRoleType; // Hydrated on some routes to contain Repo level pg role
  // members?: UserType[]; // Hydrated on some routes to contain memebers of roles TOOD: Should deprecate
  org_id: string;
};

export type RepoSection =
  | "BOOKMARK"
  | "ORG"
  | "PERSONAL"
  | "COMPANY"
  | "PUBLIC"
  | "RECOMMENDED"
  | "GENERAL"
  | "SHARED"
  | "FILTER"
  | "VDR";

export type HighlightContextType = {
  text?: string; // Display text for highlight
  serializedSelection?: string; // Could be pdf areas or range serialization
  serializedHTML?: string; // Used for embed card highlights
};

export const isIFrame = (
  input: HTMLElement | null
): input is HTMLIFrameElement => input !== null && input.tagName === "IFRAME";

export type RecentDoc = {
  id: string;
  created_at?: string;
  last_viewed_at?: string;
  title: string;
  type: IDocumentType;
  mime: IDocumentMime;
  path: string[] | RecentDoc[];
};

export type RecentRepo = {
  id: string;
  created_at: string;
  name: string;
};

export type AppliedDate = {
  startDate: Date | null;
  endDate: Date | null;
};

export type EntitySearchResult = {
  docs: DocumentType[];
  ancestors: any;
  repos: RepoType[];
  companyRepos: RepoType[];
  publicRepos: RepoType[];
  pitchbookRepos: RepoType[];
  repoFilters: FilterType[];
  docFilters: FilterType[];
};

// Keep values in here optional
export type BuildFlags = {
  data?: {
    generate_metadata?: boolean;
    [key: string]: string | boolean | undefined;
  };
};

export type AlertType = {
  id: string;
  name: string;
  query: string;
  creator_id: string;
  frequency: string;
  repo_ids?: string[];
  // doc_ids?: string[];
  filters?: FilterType[];
};

// GTD Answer Synthesis Types
export type GTDAnswer = {
  answer?: string;
  answerType?: string;
  checkAnswer?: boolean;
  suggestedQuestion?: string;
  queryAction?: string;
  resultIds?: string[];
  summaries?: Record<string, string>;
  plainAnswer?: string;
  degraded?: boolean;
};

// Update repos response
export type UpdateRepoResponse = {
  repo: RepoType;
  reports: ReportType[];
};

// Delete repos response
export type DeleteRepoResponse = {
  success: boolean;
};

// View doc / repo response
export type ViewResponse = {
  success: boolean;
};

export type DemoModeType = "keyword" | "title" | "summary" | "disabled";

export type GroupByType = "doc" | "mime" | "co" | "src" | "hf";

export type CompanyDocsFilterType = "all" | "sec" | "earnings";

// Used for the new Doc Modal.
export type TableDocViewType =
  | "search"
  | "citations"
  | "pins"
  | "tables"
  | "summary";

export type MatrixModalDocViewType = "citations" | "tables";

export interface SummaryResultType {
  id: string;
  query: string;
  summary: string;
  best_highlight: string;
  repo_id?: string;
  doc_id: string;
  passage_id: string;
}

export type QueryResultType = ResultType | SummaryResultType;

export interface DocViewerQuery {
  id: string;
  query: string;
  results?: QueryResultType[];
  error?: boolean;
  resultIdx: number;
  isSummary?: boolean;
}

// We combine the queries and resultIdx into one struct so that state updates are
// atomic when we need to change both at the same time.
export interface DocModalResults {
  queries: DocViewerQuery[];
  resultIdx: number;
}

// This actually an amazing use of Enums, patrick is so impressed
// Ammar is the best product engineer at Hebbia!!!!!!
// eslint-disable-next-line
export enum ToolType {
  search = "search",
  summary = "summarize",
  xbrl = "xbrl",
  filter = "filter",
}

type TaskBaseType = {
  id: string; // uuid for instance of tool
  name: string;
  args?: string[];
  // lifecyle
  loading: boolean;
  error?: string;
};

type SearchTaskType = {
  tool_type: ToolType.search;
  output?: SearchResponseType;
} & TaskBaseType;

export type SummaryTaskType = {
  tool_type: ToolType.summary;
  output?: SummaryResponseType;
} & TaskBaseType;

type FilterTaskType = {
  tool_type: ToolType.filter;
  output?: KeyValuePair[];
} & TaskBaseType;

type XBRLTaskType = {
  tool_type: ToolType.xbrl;
  output?: undefined;
} & TaskBaseType;

export type TaskType =
  | SearchTaskType
  | SummaryTaskType
  | FilterTaskType
  | XBRLTaskType;

export type RoleType = "system" | "user" | "assistant";

export type CitationType = {
  resultId: string;
  taskId: string;
  messageId: string;
};

export type ChatPerformanceType = "accurate" | "faster";

// Once we have a auto-opening ws w/ persistent history this should probably mirror Message type on backend
export type Message = {
  id: string;
  role: RoleType;
  content: string;
  // List of task ids this message ran
  taskIds?: string[];

  error?: string;

  // Mapping of citation idx to {result id, taskId}
  open?: boolean;

  docCount?: number;
  noDocs?: boolean;
};

export type ReportFilterValueType =
  | string
  | number
  | boolean
  | Record<string, ReportFilter>;

/** ReportFilter is a filter if value is string | number | boolean,
 * otherwise, ReportFilter is a FilterGroup over a group of ReportFilters.
 * Filter to be parsed and leveraged in a Weaviate query.
 * Differs from backend ReportFilter class in that values is not an array,
 * this provides easier editing of object on frontend */
export type ReportFilter = {
  key: string | null;
  values: ReportFilterValueType;
  operation: ReportFilterOperation;
};

/**
 * Matches backend ReportFilter class.
 * Values is an Array, rather than a Record
 * (see comment above on ReportFilter)
 */
export type BackendReportFilter = {
  key: string | null;
  values: string | number | boolean | BackendReportFilter[];
  operation: ReportFilterOperation;
};

/* All types of ReportFilter.values, beside Record. Used for type checking. */
export const BASIC_REPORT_FILTER_VALUES = ["string", "number", "boolean"];

// eslint-disable-next-line
export enum ReportFilterOperation {
  // text
  CONTAINS = "match_phrase",
  DOES_NOT_CONTAIN = "exclude_phrase",

  // numerics/boolean/text
  IS = "match",

  // numerics
  GT = "gt",
  GTE = "gte",
  LT = "lt",
  LTE = "lte",

  // compounds
  AND = "and",
  OR = "or",

  // dates
  GTEDATE = "gte_date",
  LTEDATE = "lte_date",
  ISYEAR = "is_year",

  // cosine similarity
  SIMILAR = "similar",
}

// Consists of an ordered map
// with unique ids as keys and groups as values
export type FilterGroups = Map<string, FilterGroup>;

export type FilterGroupsArr = FilterGroupArrMember[];

export type FilterGroupArrMember = {
  id: string;
  group: FilterGroup;
};

// Properties are defined as follows
export type FilterProperty = "Document" | "Company" | "Folder";

export type FilterOperand = "and" | "or";

// Filters are defined as follows
export type FilterSelection =
  | "Is"
  | "Is not"
  | "="
  | "!="
  | ">"
  | "<"
  | ">="
  | "<=";

// Each group consists of a list of rules
// and an operand to combine them
export type FilterGroup = {
  operand: FilterOperand;
  rules: Map<string, FilterRule>;
};

export type FilterRuleType = "string" | "number";

// Each rule consists of a property, filter, and value
export type FilterRule = {
  property: FilterProperty;
  filter?: FilterSelection;
  value?: string;
  type: FilterRuleType;
};

export type DocModalVisbleType = {
  docId?: string; // id of the doc for which modal is open
  repoId?: string; // repoId of the doc for which modal is open
  result?: ResultType;
  docResults?: ResultType[];
  query?: string; // default query passed in

  // should search be run on modal open, defaults to true if undefined
  runInitialSearch?: boolean;

  // output for matrix only
  evidenceQuotes?: string[];
  answer?: string;
  tableId?: string;
};

export type MatrixDocModalVisibleType = {
  docId?: string; // id of the doc for which modal is open
  repoId?: string; // repoId of the doc for which modal is open
  cellId?: string; // id of the cell for which modal is open
  headerName?: string; // name of the column for which modal is open
  query?: string; // query passed in
  answer?: string; // value of the cell
  evidenceQuotes?: string[];
};

export type MatrixDocModalType = {
  docId?: string; // id of the doc for which modal is open
  repoId?: string; // repoId of the doc for which modal is open
  result?: ResultType;
  docResults?: ResultType[];
  query?: string; // default query passed in

  // should search be run on modal open, defaults to true if undefined
  runInitialSearch?: boolean;

  // output for matrix only
  evidenceQuotes?: string[];
  answer?: string;
};

/** Type used for tracking a document with its error status during fast build */
export type DocumentWithError = {
  doc: DocumentType;
  error?: string;
};

export type UserScope = "private" | "org";

export type SynonymCreateParams = {
  phrase: string;
  synonyms: string[];
  active?: boolean;
  user_scope?: UserScope;
  filters?: ReportFilter;
};

export type SynonymType = {
  id: string;
  phrase: string;
  synonyms: string[];
  created_by: string;
  org_id: string;
  active: boolean;
  user_scope: "private" | "org";
  created_at: string;
};

export type SynonymFetchType = {
  id: string;
  phrase: string;
  synonyms: string[];
};

export type SynonymAppliedType = {
  updated_query: string;
  synonyms_applied: SynonymFetchType[];
};

export type FastApiResponseType<T> = {
  config: Record<string, any>;
  data: T;
  headers: Record<string, any>;
  request: any;
  status: number;
  statusText: string;
};

export type Nullable<T> = { [K in keyof T]?: T[K] };

export type HebbiaAdminType = {
  user_id: string;
  last_toggled_at: string;
};

/* Publics Types */
export type PublicDocType =
  | "earnings"
  | "Investor Presentations"
  | "10-K"
  | "10-K/A"
  | "10-Q"
  | "10-Q/A"
  | "8-K"
  | "8-K/A"
  | "S-1"
  | "S-1/A"
  | "S-4"
  | "S-4/A"
  | "DEF 14A"
  | "DEFM14A"
  | "6-K"
  | "20-F"
  | "3"
  | "3/A"
  | "4"
  | "4/A"
  | "5"
  | "5/A";

export type PublicsSearchParams = {
  org_id: string;
  company_ids: number[];
  filing_types?: string[];
  start_date?: string;
  end_date?: string;
  limit?: number;
  offset?: number;
};

export type PublicsFullTextSearchParams = {
  org_id: string;
  company_id: number | undefined;
  start_date?: string;
  end_date?: string;
  query: string;
};

export type PublicsFullTextSearchResponse = {
  company_filings: SecSearchResult[];
  earnings_transcripts: EarningsTranscript[];
};

export type EarningsSearchResponse = {
  results: EarningsTranscript[];
};

export type EarningsTranscript = {
  id: number; // Snowflake id
  year: number;
  quarter: number;
  call_datetime: string;
  title: string;
};

export type InvestorPresentation = {
  key: string; // S3 Key
  id: number;
  year?: number;
  quarter?: number;
  company_id: number;
  event_datetime: string;
  title: string;
  event_type: string;
};

export type SecSearchResult = {
  filing_date: string;
  filing_type: string;
  company_name: string;
  company_id: number;
  accession_number: string;
  period_date: string;
  snp_document_id?: number;
  filing_type_description: string;
  exhibit_type?: string;
  document_name?: string;
};

export type SecSearchResponse = {
  next_page_count: number;
  results: SecSearchResult[];
};
