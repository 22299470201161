/* eslint-disable react/prop-types */
import "antd/dist/antd.css";
import "emoji-mart/css/emoji-mart.css";
import "material-symbols";
import "styles/globals.css";
import "core/css/LoadingShine.css";
import "styles/LoadingSuggestions.css";
import "styles/Sidebar.css";
import "styles/Swal.css";
import "typeface-inter";
import "tailwindcss/tailwind.css";
import "styles/emoji-mart.css";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// Highlight.js styles (for chatbot)
import "styles/highlight.css";

// Import React PDF Viewer styles
import "styles/DocViewer.css";

// PDF viewer css
import "@hebbia/pdfjs-dist/web/pdf_viewer.css";
import "source/components/doc-modal/DocViewer/components/pdf/style/Highlight.css";
import "source/components/doc-modal/DocViewer/components/pdf/style/MouseSelection.css";
import "source/components/doc-modal/DocViewer/components/pdf/style/pdf_viewer.css";
import "source/components/doc-modal/DocViewer/components/pdf/style/PdfHighlighter.css";
import "source/components/doc-modal/DocViewer/components/pdf/style/Tip.css";

// Reports ag-grid
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "styles/matrix.css";
import "styles/documentList.css";

import { UserProvider } from "@auth0/nextjs-auth0/client";
import * as snippet from "@segment/snippet";
import Head from "next/head";
import { Router } from "next/router";
import React, { ComponentType, Fragment, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { useStore } from "source/redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import LogRocket from "logrocket";
import { init } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SEGMENT_ANALYTICS_KEY, SENTRY_KEY } from "source/constants";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  QueryClient,
  QueryClientProvider,
  Hydrate,
} from "@tanstack/react-query";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { LicenseManager } from "ag-grid-enterprise";
import { ClientAuthBarrier } from "source/auth/ClientAuthBarrier";
import logger from "source/utils/logger";
import { usePosthogPageView } from "../source/hooks/tracking/usePosthogPageView";
import { initialize, LDClient } from "launchdarkly-js-client-sdk";

declare global {
  interface Window {
    analytics: any;
    queryClient: any;
    ldClient: LDClient;
  }
}

init({
  dsn: `https://${SENTRY_KEY}@o1090752.ingest.sentry.io/6107154`,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.04,
  enabled: process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && !!SENTRY_KEY,
});

const renderSnippet = () => {
  const opts = {
    apiKey: SEGMENT_ANALYTICS_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  return snippet.min(opts);
};

Router.events.on("routeChangeComplete", (url) => {
  window.analytics.page(url);
});

// TODO: we may want to consider setting datadogLogs.logger.setHandler("console")
// when working in a dev environment, so that ddog logs are seen in console.
if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
  // Initialize datadog rum
  datadogRum.init({
    applicationId: "7255e257-341f-4713-9211-3599c0419c73",
    clientToken: "pubf277236efdacd50a2f2683901707d514",
    site: "datadoghq.com",
    service: "interdoc-next",
    env: "prod",
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [/https:\/\/.*\.hebbia\.ai/],
  });

  datadogRum.startSessionReplayRecording();
}

datadogLogs.init({
  clientToken: "pub88b93967b52709bec030f7098fdaac93",
  site: "datadoghq.com",
  service: "interdoc-next",
  env: process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? "prod" : "dev",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
  // Initialize logrocket
  LogRocket.init("7lppet/hebbia-production", {
    mergeIframes: true,
    ingestServer: "https://search.hebbia.ai/lr",
  });
} else {
  LogRocket.init("7lppet/hebbia-local", { mergeIframes: true });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      networkMode: "always",
    },
    mutations: {
      networkMode: "always",
    },
  },
});

// Uncomment this to directly access queryClient in the browser console (only do this in dev)
// if (typeof window !== "undefined") {
//   window.queryClient = queryClient;
// }

if (typeof window !== "undefined") {
  if (process.env.NEXT_PUBLIC_AG_GRID_LICENSE_KEY) {
    // Set AG Grid license key
    LicenseManager.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_LICENSE_KEY);
  } else {
    logger.error("Unable to find AG Grid License key.");
  }
}

const App = ({ Component, pageProps }) => {
  const store = useStore(pageProps.state);
  usePosthogPageView();

  const [LDProvider, setLDProvider] = useState<ComponentType | typeof Fragment>(
    Fragment
  );

  useEffect(() => {
    const initializeLaunchDarkly = async () => {
      const globalLDClient = initialize(
        process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID!,
        { anonymous: true, kind: "user" },
        { bootstrap: "localStorage" }
      );

      // The astute among you may be asking yourself, "What is bro cooking here?".
      // You may even ask yourself, "Has bro burnt the toast?". The answer is no.
      // With this line of code, we can access our feature flags outside of the react render cycle.
      // This means we can access the code at function runtime i.e. thunks or arbitrary functions.
      window.ldClient = globalLDClient;

      // Ensure the client finishes initialization before we try and identify the user downstream
      await globalLDClient.waitForInitialization();

      const LDProviderComponent = withLDProvider({
        ldClient: globalLDClient,
        clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID!,
        options: { bootstrap: "localStorage" }, // This may be unnecessary but I'm scared
        reactOptions: { useCamelCaseFlagKeys: false },
      });

      setLDProvider((props) => LDProviderComponent(props));
    };

    initializeLaunchDarkly();
  }, []);

  return (
    <LDProvider>
      <Head>
        <link rel="shortcut icon" href="/images/favicon.ico" />
      </Head>
      <link
        href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
        rel="stylesheet"
      />
      <script dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.6.3/css/all.css"
      />
      <script
        type="text/javascript"
        src="https://www.dropbox.com/static/api/2/dropins.js"
        data-app-key="exgd2d2b8gr0vj5"
        id="dropboxjs"
      />

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Provider store={store}>
            <UserProvider>
              <DndProvider backend={HTML5Backend}>
                <ClientAuthBarrier>
                  <Component {...pageProps} />
                </ClientAuthBarrier>
              </DndProvider>
            </UserProvider>
          </Provider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </LDProvider>
  );
};

export default App;
