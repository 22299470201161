import React, { createContext, ReactNode, useContext } from "react";

interface DocumentListModeContextValue {
  isModalMode: boolean;
  onClose?: () => void;
}

const DocumentListModeContext = createContext<DocumentListModeContextValue>({
  isModalMode: false,
});

interface Props {
  children: ReactNode;
  isModalMode: boolean;
  onClose?: () => void;
}

export const DocumentListModeProvider = ({
  children,
  isModalMode,
  onClose,
}: Props) => {
  return (
    <DocumentListModeContext.Provider value={{ isModalMode, onClose }}>
      {children}
    </DocumentListModeContext.Provider>
  );
};

export const useDocumentListMode = () => useContext(DocumentListModeContext);
