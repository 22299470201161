import { Loading } from "source/components/shared/Loading";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClientAuth } from "./useClientAuth";
import { useLDClient } from "launchdarkly-react-client-sdk";
import posthog from "posthog-js";
import { useGetRouter } from "source/hooks/useGetRouter";
import { fetchUser, getUser } from "source/redux/user";
import {
  getCurrentOrg,
  getOrgsLoaded,
  setCurrentOrg,
  upsertOrgs,
} from "source/redux/organization";
import { useAdminMode } from "source/hooks/useAdminMode";
import { POSTHOG_KEY } from "source/constants";
import { API_ENV, PROD_ENV } from "source/envConstants";
import { OrgType } from "source/Types";
import api from "source/api";
import { getLocalStorage } from "source/storage/storage";

type Props = {
  children: React.ReactNode;
};

export const ClientAuthBarrier = ({ children }: Props) => {
  const { router } = useGetRouter();
  const { loading: authUserLoading, authUser } = useClientAuth();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const ldClient = useLDClient();
  const currentOrg = useSelector(getCurrentOrg);
  const orgsLoaded = useSelector(getOrgsLoaded);
  const { prefetchHebbiaAdmin } = useAdminMode();

  const isLDIdentifying = useRef<boolean>(false);

  const loading = authUserLoading && !user && orgsLoaded;

  useEffect(() => {
    if (loading) return;
    if (!user) {
      return;
    } else if (user.is_locked) {
      router.push("/locked", undefined, {
        shallow: true,
      });
    } else if (!(user.is_email_verified || authUser?.email_verified)) {
      router.push("/verify", undefined, {
        shallow: true,
      });
    }
  }, [user?.id, loading, authUser?.email_verified]);

  const init = async () => {
    try {
      // we use localStorage.currentOrgId to send as the `current-org-id` header
      // for api calls - without this being set, calls will fail so we can't render
      // children until it's loaded
      getLocalStorage().getItem("currentOrgId");

      // Fetch user orgs and ensure currentOrg is set in redux
      api.orgs.get().then(({ data }) => {
        dispatch(upsertOrgs(data.orgs));
      });

      if (
        POSTHOG_KEY &&
        process.env.NEXT_PUBLIC_VERCEL_ENV === "production" &&
        API_ENV === PROD_ENV
      ) {
        posthog.init(POSTHOG_KEY, {
          api_host: "https://search.hebbia.ai/ingest",
        });
      }

      // Not needed before loading screen
      fetchUser(dispatch).then((user) => {
        window.analytics.identify(user.id, {
          name: user.name,
          email: user.email,
          created_at: user.created_at,
          platform_role: user.platform_role,
        });
        if (
          POSTHOG_KEY &&
          process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
        ) {
          posthog.identify(user.id, {
            name: user.name,
            email: user.email,
            created_at: user.created_at,
            platform_role: user.platform_role,
            is_locked: user.is_locked,
            unlocked_at: user.unlocked_at,
            org_ids: user.org_ids,
          });
        }
      });

      // Prefetch info about user to see if admin toggling is availible
      prefetchHebbiaAdmin();
    } catch (e) {
      console.log(e);
    }
  };

  // Run User Fetch
  useEffect(() => {
    if (!authUserLoading && authUser) init();
  }, [authUserLoading]);

  // Fetch members early
  useEffect(() => {
    if (currentOrg) {
      api.orgs.getOrgMembers(currentOrg.id).then((data) => {
        const newOrg: OrgType = {
          ...currentOrg,
          members: data.members,
          pending_members: data.pending,
        };
        dispatch(setCurrentOrg(newOrg));
      });
      return;
    }
  }, [currentOrg?.id, dispatch]);

  useEffect(() => {
    if (!ldClient || !user) return;

    // Postpone initialization until orgs are loaded
    if (!orgsLoaded) return;

    // Don't re-identify the user if LD is processing
    if (isLDIdentifying.current) return;

    const identify = async () => {
      isLDIdentifying.current = true;

      try {
        if (currentOrg?.id) {
          const userEmailDomain =
            user.email.split("@")[1]?.trim().toLowerCase() ?? "";
          await ldClient.identify({
            // this example multi-context contains two associated contexts
            // one for user and one for organization
            kind: "multi",
            user: {
              key: user.id,
              email: user.email,
              firstName: user.name,
            },
            organization: {
              key: currentOrg.id,
              name: currentOrg.name,
            },
            emailDomain: {
              key: userEmailDomain,
            },
          });
        } else {
          await ldClient.identify({
            // user is the default user where we identify someone by user.id
            kind: "user",
            key: user.id,
            email: user.email,
            firstName: user.name,
          });
        }
      } finally {
        isLDIdentifying.current = false;
      }
    };

    identify();
  }, [ldClient, user?.id, currentOrg?.id, orgsLoaded]);

  if (loading) return <Loading />;
  return <>{children}</>;
};
