import dynamic from "next/dynamic";
import { ModalTypes } from "./modals.types";

export const modalConfig: Record<ModalTypes, any> = {
  [ModalTypes.SettingsModal]: dynamic(() =>
    import("source/components/settings").then((modal) => modal.SettingsModal)
  ),
  [ModalTypes.ShareModal]: dynamic(() =>
    import("source/components/shared/Modals/ShareModal").then(
      (modal) => modal.ShareModal
    )
  ),
  [ModalTypes.ShareAsTemplateModal]: dynamic(() =>
    import("source/components/shared/Modals/ShareAsTemplateModal").then(
      (modal) => modal.ShareAsTemplateModal
    )
  ),
  [ModalTypes.BulkRunModal]: dynamic(() =>
    import("source/components/matrix/modals/BulkRunModal").then(
      (modal) => modal.BulkRunModal
    )
  ),
  [ModalTypes.CreateGigaModal]: dynamic(() =>
    import("source/components/shared/Modals/CreateGigaModal").then(
      (modal) => modal.CreateGigaModal
    )
  ),
  [ModalTypes.ShareMatrixModal]: dynamic(() =>
    import("source/components/shared/Modals/ShareMatrixModal").then(
      (modal) => modal.ShareMatrixModal
    )
  ),
  [ModalTypes.RefreshReportScreenModal]: dynamic(() =>
    import("source/components/shared/Modals/RefreshReportScreenModal").then(
      (modal) => modal.RefreshReportScreenModal
    )
  ),
  [ModalTypes.AddFolderModal]: dynamic(() =>
    import(
      "source/components/gigabar/components/FileSection/components/AddFolder/AddFolderModal"
    ).then((modal) => modal.AddFolderModal)
  ),
  [ModalTypes.WelcomeMatrixChatModal]: dynamic(() =>
    import("source/components/matrix/home/WelcomeMatrixChatModal").then(
      (modal) => modal.WelcomeMatrixChatModal
    )
  ),
  [ModalTypes.MatrixDocModal]: dynamic(() =>
    import(
      "source/components/matrix/modals/MatrixDocModal/MatrixDocModal"
    ).then((modal) => modal.MatrixDocModal)
  ),
  [ModalTypes.MatrixDocModalV2]: dynamic(() =>
    import(
      "source/components/matrix/modals/MatrixDocModalV2/MatrixDocModalV2"
    ).then((modal) => modal.MatrixDocModalV2)
  ),
  [ModalTypes.AddDocumentsModal]: dynamic(() =>
    import(
      "source/components/matrix/menu/AddDocumentsModal/AddDocumentsModalRouter"
    ).then((modal) => modal.AddDocumentsModalRouter)
  ),
  [ModalTypes.AddDocsUserPermissionsModal]: dynamic(() =>
    import("source/components/shared/Modals/AddDocsUserPermissionsModal").then(
      (modal) => modal.AddDocsUserPermissionsModal
    )
  ),
  [ModalTypes.PasswordInputModal]: dynamic(() =>
    import("source/components/matrix/build/PasswordInputModal").then(
      (modal) => modal.PasswordInputModal
    )
  ),
  [ModalTypes.DeleteRowModal]: dynamic(() =>
    import("source/components/matrix/modals/DeleteRowModal").then(
      (modal) => modal.DeleteRowModal
    )
  ),
  [ModalTypes.ForceClaimLeaseModal]: dynamic(() =>
    import("source/components/matrix/modals/ForceClaimLeaseModal").then(
      (modal) => modal.ForceClaimLeaseModal
    )
  ),
  [ModalTypes.SplitDocumentModal]: dynamic(() =>
    import(
      "source/components/matrix/modals/SplitDocumentModal/SplitDocumentModal"
    ).then((modal) => modal.SplitDocumentModal)
  ),
  [ModalTypes.ImportCSVModal]: dynamic(() =>
    import(
      "source/components/matrix/modals/ImportTabularDataModals/ImportCSVModal/ImportCSVModal"
    ).then((modal) => modal.UploadCSVModal)
  ),
  [ModalTypes.ImportExcelModal]: dynamic(() =>
    import(
      "source/components/matrix/modals/ImportTabularDataModals/ImportExcelModal/ImportExcelModal"
    ).then((modal) => modal.ImportExcelModal)
  ),
  [ModalTypes.DeleteMatrixModal]: dynamic(() =>
    import(
      "source/components/matrix/home/sidebar/modals/DeleteMatrixModal"
    ).then((modal) => modal.DeleteMatrixModal)
  ),
  [ModalTypes.S3AuthorizationModal]: dynamic(() =>
    import(
      "source/components/settings/components/Integrations/S3/S3AuthorizationModal"
    ).then((modal) => modal.S3AuthorizationModal)
  ),
  [ModalTypes.LoadMatrixFromTemplateModal]: dynamic(() =>
    import("source/components/matrix/modals/LoadMatrixFromTemplateModal").then(
      (modal) => modal.LoadMatrixFromTemplateModal
    )
  ),
};
