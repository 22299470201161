import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { listenerMiddleware } from "./listenerMiddleware";
import { OrgType } from "source/Types";
import { getLocalStorage } from "source/storage/storage";
import { ReduxState } from ".";
import { queryClient } from "../../pages/_app";

export type OrgReduxType = {
  orgs: { [id: string]: OrgType };
  currentOrg: OrgType | null;
  isLoaded: boolean;
  memberMap: { [id: string]: string };
};

const initialState: OrgReduxType = {
  orgs: {},
  currentOrg: null,
  isLoaded: false,
  memberMap: {},
};

const getOrgState = (state: ReduxState) => state.organization;
export const getOrgs = createSelector(getOrgState, (organization) =>
  Object.values(organization.orgs)
);
export const getCurrentOrg = (state: ReduxState) =>
  state.organization.currentOrg;

export const getOrgsLoaded = (state: ReduxState) => state.organization.isLoaded;

export const getMemberMap = (state: ReduxState) => state.organization.memberMap;

const orgSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    upsertOrgs: (state: OrgReduxType, action: PayloadAction<OrgType[]>) => {
      // at initialization time, set current org to be first org
      action.payload = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      action.payload.forEach((org: OrgType) => {
        state.orgs[org.id] = { ...state[org.id], ...org };
      });
      state.isLoaded = true;
      return state;
    },
    setCurrentOrg: (
      state: OrgReduxType,
      action: PayloadAction<OrgType | null>
    ) => {
      state.currentOrg = action.payload;
      if (state.currentOrg) {
        upsertOrgs([state.currentOrg]);
      }
    },
    setMemberMap: (
      state: OrgReduxType,
      action: PayloadAction<{ [id: string]: string }>
    ) => {
      state.memberMap = action.payload;
      return state;
    },
  },
});

export const { setCurrentOrg, upsertOrgs, setMemberMap } = orgSlice.actions;
export const orgReducer = orgSlice.reducer;

if (listenerMiddleware) {
  listenerMiddleware.startListening({
    actionCreator: setCurrentOrg,
    effect: (action, listenerApi) => {
      const lastState = listenerApi.getOriginalState() as ReduxState;
      const currentState = listenerApi.getState() as ReduxState;

      const didOrgChange =
        Boolean(lastState.organization.currentOrg) &&
        currentState.organization?.currentOrg?.id !==
          lastState.organization.currentOrg?.id;

      if (didOrgChange) {
        // if we switch from one org to another, clear the entire react query cache
        // this saves us from having to pass orgId to each query key
        queryClient.clear();
      }

      // save currentOrg to localStorage
      getLocalStorage().setItem(
        "currentOrgId",
        action.payload ? action.payload.id : ""
      );
    },
  });

  listenerMiddleware.startListening({
    actionCreator: upsertOrgs,
    effect: (action, listenerApi) => {
      const currentState = listenerApi.getState() as ReduxState;

      if (currentState.organization.currentOrg === null) {
        const currentOrgId = getLocalStorage().getItem("currentOrgId");
        let org = action.payload.find((org) => org.id === currentOrgId);
        if (!org) {
          org = action.payload[0];
        }
        if (org) {
          listenerApi.dispatch(setCurrentOrg(org));
        }
      }
    },
  });
}
