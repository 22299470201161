import { Transition } from "@headlessui/react";
import React, { Fragment, useEffect } from "react";

export type NotificationProps = {
  autoClose?: boolean;
  autoCloseTimeout?: number;
  children: React.ReactNode;
  closeNotification?: () => void;
};

const Notification = ({
  autoClose,
  autoCloseTimeout,
  children,
  closeNotification,
}: NotificationProps) => {
  useEffect(() => {
    if (!autoClose || !closeNotification) {
      return;
    }

    // Set new timer
    const newTimer = setTimeout(() => {
      closeNotification();
    }, autoCloseTimeout);

    // Cleanup on unmount
    return () => {
      if (newTimer) {
        clearTimeout(newTimer);
      }
    };
  }, [autoClose, autoCloseTimeout, closeNotification]);

  return (
    <Transition
      show
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto w-full max-w-md overflow-hidden rounded-xl border border-gray-200 bg-white p-4 shadow-xl">
        {children}
      </div>
    </Transition>
  );
};

export default Notification;
