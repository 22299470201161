import React from "react";
import {
  AddingColumnsMessageStep,
  DecomposingMessageStep,
  MatrixChatMessageStepType,
  MatrixChatMessageType,
} from "source/types/matrix/matrixChat.types";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { markedWithPreprocessing } from "source/utils/helpers";
import { MatrixGridApi } from "source/components/matrix/types/grid.types";
import { getYFromReportTableRow } from "source/utils/matrix/cells";
import {
  convertRangeCitationsToIndividualCitations,
  matchCitation,
  replaceTextWithCitations,
} from "../../../../utils/matrix/citations";

export const isMultiLineText = (
  input: HTMLDivElement,
  defaultHeight: number
): boolean => {
  return input.scrollHeight > defaultHeight;
};

/**
 * Given a user selection, returns the y coordinates off of aggrid row indices
 */
export const getCoordinatesForRangeSelection = (
  userSelection: number,
  gridApi: MatrixGridApi | undefined,
  retrieveColumnId?: string | undefined
): number[] | undefined => {
  if (!gridApi) return undefined;
  const selectionArray = Array.from({ length: userSelection }, (_, i) => i);
  return selectionArray.reduce((acc: number[], rowIndex) => {
    const node = gridApi.getDisplayedRowAtIndex(rowIndex);
    const y = node?.data
      ? getYFromReportTableRow({ row: node.data, retrieveColumnId })
      : undefined;
    if (y !== undefined) acc.push(y);
    return acc;
  }, []);
};

export const generativeAnswerParser = (
  text: string | undefined,
  createCitationLink: (
    citationNumber: string,
    isInDropdown: boolean
  ) => JSX.Element,
  hideCitations?: boolean
) => {
  if (!text) return;
  const htmlMarkdown = markedWithPreprocessing(text);
  const purifiedHTML = DOMPurify.sanitize(htmlMarkdown);
  return parse(purifiedHTML, {
    replace: (domNode) => {
      if (domNode.nodeType === Node.TEXT_NODE) {
        const textNode = domNode as unknown as Text;
        let text = textNode.data;
        text = convertRangeCitationsToIndividualCitations(text);
        if (text.match(matchCitation)) {
          return (
            <>
              {replaceTextWithCitations(
                text,
                createCitationLink,
                hideCitations
              )}
            </>
          );
        }
      }
    },
  });
};

export const getCitationCount = (text: string) => {
  text = convertRangeCitationsToIndividualCitations(text);
  const matches = Array.from(text.matchAll(matchCitation));
  return matches.length;
};

// Helper for getTotalMessageLengthForScroll
// Returns one plus the number of "chips" within a loading step
export const getMessageStepLength = (step: MatrixChatMessageStepType) => {
  const decompStep = step as DecomposingMessageStep | undefined;
  if (decompStep) return (decompStep.source_filters?.length ?? 0) + 1;
  const addingColsStep = step as AddingColumnsMessageStep | undefined;
  if (addingColsStep) return (addingColsStep.column_names?.length ?? 0) + 1;
  return 1;
};

// This is a proxy dependency for when there is a new token.
// When the sum of the length of content or number of loading steps changes,
// That is when we want to rerun scroll (debounced of course)
export const getTotalMessageLengthForScroll = (
  messages: MatrixChatMessageType[]
) => {
  const totalMessageLength = messages
    .map((msg) => msg.content.length)
    .reduce((a, b) => a + b, 0);
  const lastMessage = messages.length
    ? messages[messages.length - 1]
    : undefined;
  const messageStepLength =
    lastMessage?.steps
      .map((step) => getMessageStepLength(step))
      .reduce((a, b) => a + b, 0) ?? 0;
  return totalMessageLength + messageStepLength;
};

// Currently this is the only Matrix Chat step possible to undo
export const canUndoStep = (step: MatrixChatMessageStepType) => {
  return step.loading_step_type === "converting_column";
};

export const DEFAULT_AGENT_MESSAGE_ID = "system-placeholder-message";

export type GetDefaultAgentMessageProps = {
  name?: string;
};

export const getDefaultAgentMessage = ({
  name,
}: GetDefaultAgentMessageProps): MatrixChatMessageType => {
  const messageContent = `Hi${name && ` ${name}`}! Ask me anything and I will use the Matrix to answer.`;

  return {
    id: DEFAULT_AGENT_MESSAGE_ID,
    role: "system",
    content: messageContent,
    steps: [],
    loading: false,
    isFromPreviousSession: true,
  };
};

export const hasOnlyDefaultMessage = (messages?: MatrixChatMessageType[]) =>
  messages &&
  messages?.filter((message) => message.id === DEFAULT_AGENT_MESSAGE_ID)
    .length === messages?.length &&
  messages.length > 0;
