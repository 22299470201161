import { IDocumentMime } from "source/Types";

export const FASTBUILD_UPLOAD_BATCH_SIZE = 10;
export const FASTBUILD_CHUNK_SIZE_LIMIT = 1048576;

export const FAST_BUILD_SHAREPOINT_MUTATION_KEY =
  "/sheets/build/async-ingest-drive-docs";
export const CRAWL_SHAREPOINT_FOLDER_MUTATION_KEY =
  "/sheets/build/crawl-sharepoint-folder";
export const EARNINGS_FAST_BUILD_MUTATION_KEY =
  "/sheets/build/async-ingest-transcripts";
export const INVESTOR_PRESENTATIONS_FAST_BUILD_MUTATION_KEY =
  "/sheets/build/async-ingest-investor-presentations";
export const SEC_FILINGS_FAST_BUILD_MUTATION_KEY =
  "/sheets/build/async-ingest-sec";
export const AZURE_FAST_BUILD_MUTATION_KEY =
  "/sheets/build/async-ingest-file-share-docs";
export const NEW_FAST_BUILD_MUTATION_KEY = "/fastbuild/ingest-docs";

export const FAST_BUILD_ALLOWED_MIMES: IDocumentMime[] = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-outlook",
  "text/html",
];

export const FAST_BUILD_ACCEPTED_FILES_OBJECT = Object.fromEntries(
  FAST_BUILD_ALLOWED_MIMES.map((mime) => [mime, []])
);

export const FAST_BUILD_ALLOWED_MIMES_SET = new Set(FAST_BUILD_ALLOWED_MIMES);
export const FAST_BUILD_ALLOWED_EXTENSIONS: string[] = [
  ".pdf",
  ".docx",
  ".pptx",
  ".ppt",
  ".doc",
  ".msg",
  ".html",
];
// These are the mime types supported in the old fast build, still used by SharePoint
export const FAST_BUILD_ALLOWED_MIMES_OLD: IDocumentMime[] = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "text/html",
  "application/vnd.ms-outlook",
];
export const FAST_BUILD_ALLOWED_MIMES_SET_OLD = new Set(
  FAST_BUILD_ALLOWED_MIMES_OLD
);

export const EXTENSION_TO_MIME_TYPE: Record<string, string> = {
  pdf: "application/pdf",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  doc: "application/msword",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ppt: "application/vnd.ms-powerpoint",
  msg: "application/vnd.ms-outlook",
  html: "text/html",
};

export const FAST_BUILD_ERROR_PARSING = "Error while parsing file";

export const ALLOWED_EXTENSIONS_STR = FAST_BUILD_ALLOWED_EXTENSIONS.join(",");

export const MAX_ROWS_IN_BULK_RUN = 100;
export const TEXT_20_MEGABYTES = 20 * 1024 * 1024;
export const MAX_FAST_BUILD_FILE_SIZE = 125 * 1024 * 1024; // 250 KB per page * 500 = 125 MB

export const MS_GRAPH_BUILD_CLIENT_ID = "29615c28-3d00-4a1d-a856-a349469fe14f";
export const MS_GRAPH_SCOPED_CLIENT_ID = "1f55ef4a-6a64-4cfb-ba24-c721c6b512fd";

export const BOX_CLIENT_ID = "lszb2re8jwbibeyfm32eol3vgl0lrzdu"; // TODO: Shouldnt this be a secret
export const BOX_DEFAULT_SCOPE = "root_readwrite";
export const ERROR_MESSAGE_URL_LIMIT = 3;
