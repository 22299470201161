import { ReduxState } from ".";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EvidenceQuoteV2 } from "source/components/matrix/types/cells.types";

export type DocViewerReduxType = {
  selectedQuoteId: string | null;
  selectedColumnId: string | null;
  columnMap: {
    [columnId: string]: {
      evidenceQuoteIds: string[];
    };
  };
  evidenceQuoteMap: {
    [evidenceQuoteId: string]: string | EvidenceQuoteV2;
  };
};

const initialState: DocViewerReduxType = {
  selectedQuoteId: null,
  selectedColumnId: null,
  columnMap: {},
  evidenceQuoteMap: {},
};

export const getSelectedQuoteId = (state: ReduxState) =>
  state.docViewer.selectedQuoteId;
export const getSelectedColumnId = (state: ReduxState) =>
  state.docViewer.selectedColumnId;
export const getColumns = (state: ReduxState) => state.docViewer.columnMap;
export const getEvidenceQuoteMap = (state: ReduxState) =>
  state.docViewer.evidenceQuoteMap;

export const getEvidenceQuoteIdsForColumn = (columnId: string) =>
  createSelector(
    [(state: ReduxState) => state.docViewer.columnMap],
    (columnMap) => columnMap[columnId]?.evidenceQuoteIds ?? []
  );

const docViewerSlice = createSlice({
  name: "docViewer",
  initialState,
  reducers: {
    setSelectedQuoteId: (state, action: PayloadAction<string | null>) => {
      state.selectedQuoteId = action.payload;
    },
    setSelectedColumnId: (state, action: PayloadAction<string>) => {
      state.selectedColumnId = action.payload;
    },
    setColumnMap: (
      state,
      action: PayloadAction<{
        [columnId: string]: { evidenceQuoteIds: string[] };
      }>
    ) => {
      state.columnMap = action.payload;
    },
    addEntryToColumnMap: (
      state,
      action: PayloadAction<{
        columnId: string;
        evidenceQuoteIds: string[];
      }>
    ) => {
      state.columnMap[action.payload.columnId] = {
        evidenceQuoteIds: action.payload.evidenceQuoteIds,
      };
    },
    setEvidenceQuoteMap: (
      state,
      action: PayloadAction<{
        [evidenceQuoteId: string]: string | EvidenceQuoteV2;
      }>
    ) => {
      state.evidenceQuoteMap = action.payload;
    },
  },
});

export const {
  setSelectedQuoteId,
  setSelectedColumnId,
  setColumnMap,
  addEntryToColumnMap,
  setEvidenceQuoteMap,
} = docViewerSlice.actions;
export const docViewerReducer = docViewerSlice.reducer;
