export enum ModalTypes {
  SettingsModal = "SettingsModal",
  ShareModal = "ShareModal",
  ShareAsTemplateModal = "ShareAsTemplate",
  BulkRunModal = "BulkRunModal",
  CreateGigaModal = "CreateGigaModal",
  ShareMatrixModal = "ShareMatrixModal",
  RefreshReportScreenModal = "RefreshReportScreenModal",
  AddFolderModal = "AddFolderModal",
  WelcomeMatrixChatModal = "WelcomeMatrixChatModal",
  MatrixDocModal = "MatrixDocModal",
  MatrixDocModalV2 = "MatrixDocModalV2",
  PasswordInputModal = "PasswordInputModal",
  DeleteRowModal = "DeleteRowModal",
  ForceClaimLeaseModal = "ForceClaimLeaseModal",
  SplitDocumentModal = "SplitDocumentModal",
  ImportCSVModal = "ImportCSVModal",
  ImportExcelModal = "ImportExcelModal",
  DeleteMatrixModal = "DeleteMatrixModal",
  S3AuthorizationModal = "S3AuthorizationModal",
  LoadMatrixFromTemplateModal = "LoadMatrixFromTemplateModal",
  AddDocumentsModal = "AddDocumentsModal",
  AddDocsUserPermissionsModal = "AddDocsUserPermissionsModal",
}
