import { DocumentStatusCount } from "source/types/document-list/documentList.types";
import { ReduxState } from ".";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { IServerSideGroupSelectionState } from "ag-grid-community";

export type DocumentListReduxType = {
  numTotalRows: number | null;
  isSelectionLoading: boolean;
  isDocumentStatusPollingEnabled: boolean;
  forceGridRefresh: boolean;
  documentStatusCount: DocumentStatusCount | null;
  activeDocumentListId: string | null;
  isGridDragging: boolean;
  rootFolderId: string | null;
  documentListSelection: IServerSideGroupSelectionState;
};

const initialState: DocumentListReduxType = {
  isSelectionLoading: false,
  numTotalRows: null,
  isDocumentStatusPollingEnabled: true,
  forceGridRefresh: false,
  documentStatusCount: null,
  activeDocumentListId: null,
  isGridDragging: false,
  rootFolderId: null,
  documentListSelection: {
    selectAllChildren: false,
  },
};

const getAllSelectedDocumentIds = (state) =>
  state.addDocs.documentListSelectionState;
const getExistingDocumentIds = (state) => state.addDocs.existingDocumentIds;
const getNewDocumentIds = (state) => state.addDocs.newDocumentIds;

export const getSelectedDocumentIds = createSelector(
  getAllSelectedDocumentIds,
  getExistingDocumentIds,
  getNewDocumentIds,
  (docListIds, existingDocIds, newDocIds) => {
    // users can select across multiple document lists at once time, so we need to combine the selected ids
    // the existing docs in the matrix before add docs modal was opened, there might be dupes with docListIds above
    // any new documents added through the legacy integrations panels
    return Array.from(
      new Set([
        ...Object.values(docListIds).flat(),
        ...existingDocIds,
        ...newDocIds,
      ])
    );
  }
);

export const getNumSelectedRows = (state: ReduxState) => {
  return getSelectedDocumentIds(state).length;
};

export const getIsSelectionStateLoading = (state: ReduxState) =>
  state.documentList.isSelectionLoading;

export const getNumTotalRows = (state: ReduxState) =>
  state.documentList.numTotalRows;

export const getIsDocumentStatusPollingEnabled = (state: ReduxState) =>
  state.documentList.isDocumentStatusPollingEnabled;

export const getForceGridRefresh = (state: ReduxState) =>
  state.documentList.forceGridRefresh;

export const getDocumentStatusCount = (state: ReduxState) =>
  state.documentList.documentStatusCount;

export const getActiveDocumentListId = (state: ReduxState) =>
  state.documentList.activeDocumentListId;

export const getIsGridDragging = (state: ReduxState) =>
  state.documentList.isGridDragging;

export const getRootFolderId = (state: ReduxState) =>
  state.documentList.rootFolderId;

const documentListSlice = createSlice({
  name: "documentList",
  initialState,
  reducers: {
    setIsSelectionLoading: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectionLoading = action.payload;
      return state;
    },
    setNumTotalRows: (
      state: DocumentListReduxType,
      action: PayloadAction<number | null>
    ) => {
      state.numTotalRows = action.payload;
      return state;
    },
    setIsDocumentStatusPollingEnabled: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isDocumentStatusPollingEnabled = action.payload;
      return state;
    },
    setForceGridRefresh: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.forceGridRefresh = action.payload;
      return state;
    },
    setDocumentStatusCount: (
      state: DocumentListReduxType,
      action: PayloadAction<DocumentStatusCount | null>
    ) => {
      state.documentStatusCount = action.payload;
      return state;
    },
    setIsGridDragging: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isGridDragging = action.payload;
      return state;
    },
    resetAll: (state: DocumentListReduxType) => {
      state = {
        ...initialState,
        activeDocumentListId: state.activeDocumentListId,
      };
      return state;
    },
    setActiveDocumentListId: (
      state: DocumentListReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.activeDocumentListId = action.payload;
      return state;
    },
    setRootFolderId: (
      state: DocumentListReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.rootFolderId = action.payload;
      return state;
    },
  },
});

export const {
  setNumTotalRows,
  setIsDocumentStatusPollingEnabled,
  setDocumentStatusCount,
  setForceGridRefresh,
  setActiveDocumentListId,
  setIsGridDragging,
  setIsSelectionLoading,
  resetAll,
  setRootFolderId,
} = documentListSlice.actions;
export const documentListReducer = documentListSlice.reducer;
